import AdminToken from './AdminToken.js';
import API_DOMAIN from './CONFIG.js';

const BASE_URL = `${API_DOMAIN}admins/students/`;

async function fetchUsers() {
  const getUsers = () => {
    return fetch(BASE_URL, {
      method: "GET",
      mode: "cors",
      cache: 'default',
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
    });
  }

  const result = await getUsers();
  return result;
}

async function getAvailableCourses(id) {
  const URL = `${API_DOMAIN}admins/${id}/courses/`;
  const getCourses = () => {
    return fetch(URL, {
      method: "GET",
      mode: "cors",
      cache: 'default',
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      },
    });
  }

  const result = await getCourses();
  return result;
} 

export { fetchUsers, getAvailableCourses };