import React from "react";
import './UserCard.scss';

class UserCard extends React.Component {
  constructor (props) {
    super (props);

    this.state = {
      object: this.props.object,
    }
  }

  render() {
    const { object } = this.state;
    return(
      <div className="user__card">
        <div className="grid__table row">
          <div className="table__item info" onClick={() => this.props.openProfile(object.user.id)}>
            <div className="table__item--photo" style={{backgroundImage:`url(${object.photo})`}}>
            </div>
            <div className="table__item--info">
              <div className="info__name">{object.user.name}</div>
              <div className="info__email">{object.user.email}</div>
            </div>
          </div>
          <div className="table__item phone">{'+' + object.user.phone_number}</div>
          <div className="table__item location">{
            object.city === null || object.city.length === 0
            ? '-'
            : object.city
          }</div>
          <div className="table__item date">{object.date_joined.split('-').reverse().join('.')}</div>
        </div>
      </div>
    );
  }
}

export default UserCard;