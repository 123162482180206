import React, { useState } from 'react';
import './AdminCreateForm.scss';
import { createAdmin } from '../../../API/Admins';

const AdminCreateForm = ({handleClose, show, children}) => {
  const showHideClassName = show ? "admin-creation-form__menu display-block" : "admin-creation-form__menu display-none";
  const [name,setName] = useState('');
  const [phone,setPhone] = useState('');
  const [email,setEmail] = useState('');
  const [isManager,setIsManager] = useState(false);
  const [isAdmin,setIsAdmin] = useState(false);
 
  const handleSubmit = (event) => {
    event.preventDefault();

    if (phone[0] === '+') {
      setPhone(phone.split('+').join(''));
    }

    const body = {
      user: {
        name: name,
        email: email,
        phone_number: phone,
      },
      is_admin: isAdmin,
      is_manager: isManager,
    };

    const result = createAdmin(JSON.stringify(body));
    result.then((data) => {
      if (data.status === 403) {
        throw new Error('Менеджерам нельзя создавать новых администраторов.');
      }

      if (data.status === 400) {
        return data.json();
      }

      if (!data.ok) {
        throw new Error('Не удалось создать администратора');
      }
      handleClose();
      return {};
    })
    .then((dataJSON) => {
      if (!('user' in dataJSON)) {
        return;
      }
      switch(true) {
        case ('email' in dataJSON.user):
          alert('Администратор с такой почтой уже сущетвует.');
          break;
        case (dataJSON.user.phone_number[0] === "my user with this phone number already exists."):
          alert('Администратор с таким номером телефона уже сущетвует.');
          break;
        case (dataJSON.user.phone_number[0] === "Phone number must be entered in the format: '380999999999'."):
          alert('Введите номер в формате 380999999999');
          break;
        default:
         break;
      }
    }).catch(alert);
  }

  return(
    <div className="blur__background">
      <div className={showHideClassName}>
        <div className="menu__header">
          <div className="menu__header--name">
            Добавьте администратора
          </div>
          <div
            className="menu__header--close-button"
            onClick={() => handleClose()}
          />
        </div>
        <form action="POST" className="menu__form" onSubmit={(e) => handleSubmit(e)}>
        <div className="menu__form--main">
          <div className="form__info">
            <div className="form__info--full-name">
              <label htmlFor="full-name" className="label">
              Имя Фамилия
              </label>
              <input
                type="text"
                name="full-name"
                id="full-name"
                className="full-name text-input"
                placeholder="Напишите имя и фамилию администратора"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form__info--phone">
              <label htmlFor="phone" className="label">
                Телефон
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                className="phone text-input"
                placeholder="380*********"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form__info--email">
              <label htmlFor="email" className="label">
                E-mail
              </label>
              <input
                type="text"
                name="email"
                id="email"
                className="email text-input"
                placeholder="Напишите e-mail администратора"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
          <div className="menu__form--categories">
            <div className="categories__header">
              Права доступа
            </div>
            <div className="categories__wrapper">
              <input
                type="radio"
                name="access"
                id="manager-checkbox"
                className="radio-button"
                onChange={(e) => {setIsManager(e.target.checked);setIsAdmin(!e.target.checked)}}
                required
              />
              <div className="label">
                Менеджер
              </div>
              <input
                type="radio"
                name="access"
                id="admin-checkbox"
                className="radio-button"
                onChange={(e) => {setIsAdmin(e.target.checked);setIsManager(!e.target.checked)}}
                required
              />
              <div className="label">
                Администратор
              </div>
            </div>
          </div>
          <div className="menu__form--control">
            <button type="reset" className="button cancel-button">
              Отменить
            </button>
            <button type="submit" className="button submit-button">
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}


export default AdminCreateForm;