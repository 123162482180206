import React from "react";
import './BookingList.scss';
import Pagination from "../Pagination/Pagination";
import classNames from "classnames";
import {getBookings} from "../../API/Bookings";
import BookingCard from "./BookingCard/BookingCard";

const PageSize = 10;


class BookingList extends React.Component {
  state = {
    bookingsArr: [],
    currentPage: 1,
    filterQuery: 'all',
    searchQuery: '',
  }

  componentDidMount() {
    this.downloadBookings();
  }

  downloadBookings() {
    const result = getBookings();
    result.then(data => {
      if (!data.ok) {
        throw new Error('Не удалось загрузить список');
      }
      return data.json();
    }).then(dataJSON => {
      this.setState(() => ({bookingsArr: dataJSON}));
    }).catch(alert);
  }

  setCurrentPage(value) {
    this.setState({currentPage: value});
  }

  filterBookings() {
    const { filterQuery, bookingsArr } = this.state;
    switch(filterQuery) {
      case 'all' :
        return [...bookingsArr];
      case 'pending':
        return bookingsArr.filter(item => item.status === false);
      case 'booked':
        return bookingsArr.filter(item => item.status === true);
      default:
        return [...bookingsArr];
    }
  }

  setFilterQuery(value) {
    this.setState(() => ({filterQuery: value}));
  }

  setSearchQuery(e) {
    this.setState(() => ({searchQuery: e.target.value}))
  }

  searchBookings(array) {
    const { searchQuery } = this.state;
    return array.filter(item => item.student.user.name.toLowerCase().includes(searchQuery.toLowerCase())
    || item.student.user.phone_number.includes(searchQuery));
  }

  render() {
    const filteredArr = this.searchBookings(this.filterBookings());
    const { currentPage,searchQuery } = this.state;
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = filteredArr.slice(firstPageIndex, lastPageIndex);
    const firstNumber = firstPageIndex + 1;
    let lastNumber = lastPageIndex;
    if (lastPageIndex <= filteredArr.length) {
      lastNumber = lastPageIndex;
    } else {
      lastNumber = filteredArr.length;
    }
    return(
      <div className="booking-list__page">
        <div className='page__header'>
          <div className='info'>
            <h2 className='info__title'>Бронирование записи</h2>
            <div className="info__breadcrumbs">
              <span className="info__start-point">Практические тренинги </span>
              <span className="info__end-point">/ Бронирование записи</span>
            </div>
          </div>
          <div className='navigation'>
            <input
              type="search"
              placeholder='Поиск по номеру телефона или имени'
              className='navigation__search-bar'
              value={searchQuery}
              onChange={(event) => this.setSearchQuery(event)}
            />
            <div className="navigation__filters">
              <div
                className={classNames("filter-button", {'active': this.state.filterQuery === "all"})}
                onClick={() => this.setFilterQuery('all')}
              >
                Все
              </div>
              <div
                className={classNames("filter-button", {'active': this.state.filterQuery === "pending"})}
                onClick={() => this.setFilterQuery('pending')}
              >
                В ожидании
              </div>
              <div
                className={classNames("filter-button", {'active': this.state.filterQuery === "booked"})}
                onClick={() => this.setFilterQuery('booked')}
              >
                Забронированные
              </div>
            </div>
          </div>
        </div>
        <div className="page__pagination">
          <div className="page__table">
            <div className="grid__table table__head">
              <div className="head__name head">Имя / E-mail</div>
              <div className="head__phone head">Телефон</div>
              <div className="head__location head">Страна / город</div>
              <div className="head__dates head">Дата посещений</div>
              <div className="head__status head">Статус брони</div>
            </div>
            <div className="table__content">
              {
                currentData.map((item) => {
                  return(
                    <BookingCard
                      object={item}
                      key={item.id}
                      update={this.downloadBookings.bind(this)}
                    />
                  );
                })
              }
            </div>
          </div>
          <div className='pagination__nav'>
            <div className='pagination__nav--number'>
              {`${firstNumber}-${lastNumber} из ${filteredArr.length}`}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={filteredArr.length}
              pageSize={PageSize}
              onPageChange={page => this.setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BookingList;