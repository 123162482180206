import React from "react";
import './ModuleCard.scss';
import classNames from "classnames";
import { getModuleResults, getTestResults, editTestResult } from '../../../API/Modules';
import TestResultList from "./TestResultList/TestResultList";

class ModuleCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      module: this.props.module,
      moduleResults: [],
      userId: this.props.userId,
      result: {},
      testResult: [],
      formIsVisible: false,
      status: 'Closed',
    }
  }

  componentDidMount() {
    this.downloadModuleResults(); 
  }

  downloadModuleResults() {
    const resultResponse = getModuleResults(this.state.userId);
    resultResponse.then(data => {
      if (!data.ok) {
        throw new Error ('Не удалось загрузить результаты модулей');
      }
      return data.json();
    }).then(dataJSON => {
      let result = dataJSON.find(item => item.module.id === this.state.module.id);

      if (result !== undefined) {
        this.setState(() => ({
          result: result,
          status: result.status,
        }));
      } else {
        this.setState(() => ({
          result: {},
          status: 'Closed',
        }));
      }
      
    }).catch(alert);
  }

  showTestResult(moduleResult) {
    const response = getTestResults(moduleResult.id);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('Не удалось загрузить результаты теста');
        }
        return data.json();
      }
    ).then(
      dataJSON => {
        this.showForm(dataJSON);
      }
    ).catch(alert);
  }

  showForm(testResult) {
    this.setState(() => ({
      testResult: testResult,
      formIsVisible: true,
    }));
  }

  hideForm() {
    this.setState(() => ({formIsVisible: false}));
  }

  handleStatusChange(e) {
    this.setState({status: e.target.value});
    this.editResultStatus(e);
  }

  editResultStatus(e) {
    const formData = new FormData();
    formData.append('status', e.target.value);
    const response = editTestResult(this.state.result.id, formData);
    response.then(
      data => {
        if (data.status === 403) {
          throw new Error('Менеджерам нельзя изменять статус модуля.');
        } 

        if (!data.ok) {
          throw new Error('Не удалось сменить статус');
        }

        this.props.refresh();
      }
    ).catch(alert);
  }

  
  render() {
    const { module, result, status } = this.state;
    let moduleResult = {
      amount_of_finished_lessons: 0,
      percent_of_correct_answers: 0,
      status: "Closed",
    };
    if (typeof result !== 'undefined') {
      moduleResult = {...result};
    }

    console.log(status);
    return(
      <div className="module__card">
        <div
          className="grid__table row"
        >
          <div className="table__item">{module.id}</div>
          <div className="table__item">{module.title}</div>
          <div className="table__item">{
            moduleResult.id
            ? `${moduleResult.amount_of_finished_lessons}/${module.amount_of_lessons}`
            : '-'
          }</div>
          <div className="table__item percentage">{
            moduleResult.id
            ? moduleResult.percent_of_correct_answers + '%'
            : '-'
          }</div>
          <div
            className={classNames("table__item tests",{disabledTest: !moduleResult.id})}
            onClick={() => this.showTestResult(moduleResult)}
          >{
            moduleResult.id
            ? 'Посмотреть тест'
            : '-'
          }</div>
          <div
            className={classNames("table__item status",
            {"status--in-progress": status === 'In progress'},
            {'status--closed': status === 'Closed'}
            )}
          >
             <select
              className="status-select"
              value={this.state.status}
              disabled={!('id' in this.state.result) || status === 'Done'}
              onChange={(e) => this.handleStatusChange(e)}
            >
              <option value="In progress">В прогрессе</option>
              <option value="Done">Закончен</option>
              <option value="Closed">Закрыт</option>
            </select>
          </div>
        </div>
        {
          this.state.formIsVisible &&
          <TestResultList 
            show={this.state.formIsVisible}
            handleClose={this.hideForm.bind(this)}
            testResult={this.state.testResult}
          />
        }
      </div>
    );
  }
}

export default ModuleCard