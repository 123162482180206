import React from 'react';
import './scientistslist.scss';
import ScientistCard from './ScientistCard/ScientistCard.jsx';
import Pagination from '../Pagination/Pagination.jsx';
import CreationForm from './CreationForm/CreationForm.jsx';
import {fetchScientist} from '../../API/Scientists/ScientistList'; 

async function getScientist(page) {
  const result = await fetchScientist(page);
  return result;
}

const PageSize = 10;

class ScientistsList extends React.Component {
  constructor(props) {
    super(props);
    this.useResult = this.useResult.bind(this);
  }


  state = {
    currentPage: 1,
    scientistsArray: [],
    searchQuery: '',
    formIsVisible: false,
    count: 0, 
  }


  componentDidMount() { 
    this.downloadScientist();
    //скачивание после загрузки елемента
  }

  downloadScientist(page = 1, search = '') {
    let result = getScientist(`?page=${page}&name=${search}`);
    result.then (
      (data) => {
        if (!data.ok) {
          throw new Error('Не удалось загрузить список');
        }
        return data.json();
      }
    ).then((dataJSON) => {
      this.useResult(dataJSON);
    }).catch(alert);  
  }

  componentDidUpdate() {
  }

  useResult(val) {
    if (val.length === 0) {
      this.setState(() => ({
        scientistsArray: [],
        count: 0,
      }));
    } else {
      this.setState(() => ({
        scientistsArray: val.results,
        count: val.count,
      }));
    }
    //запись данных из промиса в стейт
  }

  setCurrentPage(value) {
    //установка значения страницы
    this.setState({currentPage: value});
      this.downloadScientist(value)
  }

  setSearchQuery(event) {
    this.setState(() => {return({searchQuery: event.target.value})});
    this.downloadScientist(1,event.target.value);
  }

  showForm() {
    this.setState((oldState) => {return({formIsVisible: true})})
    //показать форму
  }

  hideForm() {
    this.setState({formIsVisible: false});
    this.downloadScientist(this.state.currentPage);
  }
  

  render() {
    const { currentPage, count, scientistsArray } = this.state;
    const filteredArray = scientistsArray;
    filteredArray.sort((a,b) => a.id - b.id);
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = filteredArray;
    const firstNumber = firstPageIndex + 1;
    let lastNumber = lastPageIndex;
    if (lastPageIndex <= count) {
      lastNumber = lastPageIndex;
    } else {
      lastNumber = count;
    }
    //расчет диапазона карточек для пагинации

    return(
      <div className='page'>
        <div className='page__header'>
          <div className='info'>
            <h2 className='info__title'>Научные деятели</h2>
          </div>
          <div className='navigation'>
            <input
              type="text"
              placeholder='Поиск по имени'
              className='navigation__search-bar'
              value={this.state.searchQuery}
              onChange={(event) => this.setSearchQuery(event)}
            />
            <button 
              className='navigation__add-button'
              onClick={() => this.showForm()}
            >
              Добавить
            </button>
            {/* хедер с названием и основной навигацией */}
          </div>
          {this.state.formIsVisible &&
            <CreationForm show={this.state.formIsVisible} handleClose={this.hideForm.bind(this)}/>
          }
        </div>
        <div className='page__pagination'>
          <div className="page__pagination--wrapper">
            <div className="grid__table table__head">
              <div className="head__number head">№</div>
              <div className="head__photo head">Фото</div>
              <div className="head__name head">Имя Фамилия</div>
              <div className="head__desc head">Краткое описание</div>
              <div className="head__edit head">Ред.</div>
            </div>
            <div className='pagination__list'>
              {currentData.map((item, index) => {
                return (
                  <>
                    <ScientistCard
                      update={this.downloadScientist.bind(this)}
                      key={item.id}
                      obj={item}
                      currentPage={this.state.currentPage}/>
                  </>
                )
                //список карточек из полученной информации
              })}
            </div>
          </div>
          <div className='pagination__nav'>
            <div className='pagination__nav--number'>
              {`${firstNumber}-${lastNumber} из ${count}`}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={count}
              pageSize={PageSize}
              onPageChange={page => this.setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    );
  }
}


export default ScientistsList;