import React from "react";
import './UserList.scss';
import Pagination from "../Pagination/Pagination";
import UserCard from "./UserCard/UserCard";
import UserProfile from "./UserProfile/UserProfile";
import { fetchUsers } from "../../API/Users";

async function getUsers() {
  const result = await fetchUsers();
  return result;
}

const PageSize = 10;

class UserList extends React.Component  {
  state = {
    searchQuery: '',
    profileIsVisible: false,
    usersArr: [],
    currentPage: 1,
    count: 0,
    userId: -1,
  }

  componentDidMount() {
    this.downloadUsers();
  }

  useData(val) {
    this.setState(() => ({usersArr: val}));
  }

  downloadUsers() {
    let result = getUsers();
    result.then((data) => {
      if (!data.ok) {
        throw new Error('Не удалось загрузить список');
      }
      return data.json();
    }).then((dataJSON) => {
      this.useData(dataJSON.sort((a,b) => b.id - a.id));
    }).catch(alert);
  }

  setCurrentPage(value) {
    this.setState({currentPage: value});
    if (value === 1) {
      this.downloadUsers();
    } else {
      this.downloadUsers(`?page=${value}`);
    }
  }

  setUserId(id = -1) {
    this.setState(() => ({userId: id}));
    if (id !== -1) {
    }
  }

  setSearchQuery(event) {
    this.setState(() => ({
      currentPage: 1,
      searchQuery: event.target.value
    }));
  }

  showForm() {
    this.setState(() => {return({formIsVisible: true})})
  }

  hideForm() {
    this.setState(() => {return({formIsVisible: false})});
    this.downloadUsers (`?page=${this.state.currentPage}`);
  }

  searchFilter() {
    return this.state.usersArr.filter(
      item => item.user.name.toLowerCase().includes(this.state.searchQuery.toLowerCase()) ||
      item.user.phone_number.includes(this.state.searchQuery));
  }

  render() {
    const { currentPage } = this.state;
    const filteredArray = this.searchFilter();
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = filteredArray.slice(firstPageIndex, lastPageIndex);
    const firstNumber = firstPageIndex + 1;
    let lastNumber = lastPageIndex;
    if (lastPageIndex <= filteredArray.length) {
      lastNumber = lastPageIndex;
    } else {
      lastNumber = filteredArray.length;
    }

    return (
      <div className="user-list__page">
        {
          this.state.userId !== -1
          ? <UserProfile
              object={this.state.usersArr.find(item => item.user.id === this.state.userId)}
              closeProfile={this.setUserId.bind(this)}
            />
          : (<>
        <div className='page__header'>
          <div className='info'>
            <h2 className='info__title'>Пользователи</h2>
          </div>
          <div className='navigation'>
            <input
              type="search"
              placeholder='Поиск по имени и номеру телефона'
              className='navigation__search-bar'
              value={this.state.searchQuery}
              onChange={(event) => this.setSearchQuery(event)}
            />
          </div>
        </div>
        <div className="page__pagination">
          <div className="page__table">
            <div className="grid__table table__head">
              <div className="head__name head">Имя / E-mail</div>
              <div className="head__phone head">Телефон</div>
              <div className="head__location head">Страна / город</div>
              <div className="head__date head">Дата регистрации</div>
            </div>
            {
              currentData.map(
                (item) => {return (
                  <UserCard
                    object={item}
                    openProfile={this.setUserId.bind(this)}
                    key={item.id}
                  />
                )}
              )
            }
          </div>
          <div className="pagination__nav">
          <div className='pagination__nav--number'>
            {`${firstNumber}-${lastNumber} из ${filteredArray.length}`}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={filteredArray.length}
              pageSize={PageSize}
              onPageChange={page => this.setCurrentPage(page)}
            />
          </div>
        </div>
        </>)
        }
      </div>
    );
  }
}

export default UserList;