import React from "react";
import './PublicationCard.scss';
import PublicationForm from "../PublicationForm/PublicationForm";

class PublicationCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formIsVisible: false,
    }
  }

  showForm() {
    this.setState(() => {return({formIsVisible: true})})
  }

  hideForm(isDeleting = false) {
    this.setState(() => {return({formIsVisible: false})});
    if (isDeleting) {
      this.props.update();
    } else {
      this.props.update(this.props.page);
    }
  }

  render() {
    const { object } = this.props
    return(
      <div className="publication__card">
        <div className="grid__table row">
          <div className="table__item number">{object.number}</div>
          <div className="table__item photo">
            <div className="table-item__photo" style={{backgroundImage:'url(' + object.photo + `)`}}>
            </div>
          </div>
          <div className="table__item name">{object.title}</div>
          <div className="table__item date">{object.date.split('-').reverse().join('.')}</div>
          <div className="table__item">{
            object.description.split(' ').length > 10
            ? object.description.split(' ').slice(0,10).join(' ') + '...'
            : object.description.split(' ').slice(0,10).join(' ')
          }</div>
          <div className="table__item edit__item">
            <div
              className="edit-button"
              onClick={() => {this.showForm()}}></div>
          </div>
        </div>
        {this.state.formIsVisible &&
          <PublicationForm
            show={this.state.formIsVisible}
            handleClose={this.hideForm.bind(this)}
            object={this.props.object}
            isEditing={true}
          />
        }
      </div>
    )
  }
}

export default PublicationCard;