import React from "react";
import './RecordForm.scss';
import { 
  createTraining,
  getTrainings,
  deleteTraining,
  deleteRecord,
  editTraining,
  editRecord,
  createRecord,
 } from '../../../API/Trainings.js';

class RecordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      city: '',
      records: [],
    }
  }

  componentDidMount() {
    if (this.props.isEditing) {
      const result = getTrainings(this.props.id);

      result.then(data => {
        if (!data.ok) {
          throw new Error('Не удалось загрузить список');
        }
        return data.json(); 
      }).then(dataJSON => {
        this.setState(() => ({
          city: dataJSON.city,
          records: dataJSON.records,
        }));
      }).catch(alert);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { city,records } = this.state;

    if (city.length === 0) {
      alert('Укажите город.');
      return;
    }

    if (records.length === 0) {
      alert('Создайте окно записи.');
      return;
    }

    const body = {
      city: city,
      records: records,
    }

    if (this.props.isEditing) {
      this.handleEdit();
    } else {
      const result = createTraining(JSON.stringify(body));
      result.then((data) => {
        if (!data.ok) {
          throw new Error('Не удалось создать обьект');
        }
        this.props.handleClose();
        return data.json();
      }).catch(alert);
    }
  }

  handleDelete() {
    const result = deleteTraining(this.props.id);
    result.then(data => {
      if (!data.ok) {
        throw new Error('Не удалось удалить обьект');
      }
      this.props.handleClose();
    }).catch(alert);
  }

  handleEdit() {
    const { city, records } = this.state;
    const result = editTraining(this.props.id,{city: city});
    result.then(data => console.log('Training edit:',data.ok));

    for (let item of records) {
      const body = {
        date_from: item.date_from,
        date_to: item.date_to,
      }
      if (item.hasOwnProperty('id')) {
        const editResult = editRecord(item.id, body);
        editResult.then(data => {
          if (!data.ok) {
            throw new Error('Не удалось отредактировать обьект');
          }
        }).catch(alert);
      } else {
        body.training = this.props.id;
        const createResult = createRecord(body);
        createResult.then(data => {
          if (!data.ok) {
            throw new Error('Не удалось создать обьект');
          }
        }).catch(alert);
      }
    }

    this.props.handleClose();
  }

  handleReset() {
    this.setState(() => ({
      city: '',
      records: [],
    }));
  }

  addRecord() {
    const record = {
      date_from: '',
      date_to: '',
    };
    this.setState(state => ({records: state.records.concat(record)}));
  }

  changeDateFrom(e, index) {
    const arr = [...this.state.records];
    arr[index].date_from = e.target.value;
    this.setState(state => ({records: [...arr]}));
  }

  changeDateTo(e, index) {
    const arr = [...this.state.records];
    arr[index].date_to = e.target.value;
    this.setState(state => ({records: [...arr]}));
  }

  deleteRecord(index, id) {
    const arr = [...this.state.records];
    this.setState(state => ({records: [...arr]}));

    if (this.props.isEditing) {
      const result = deleteRecord(id);
      result.then(data => {
        if (!data.ok) {
          throw new Error('Не удалось удалить обьект')
        }
      }).catch(alert);
    }
  }

  render() {
    const {handleClose, show, isEditing} = this.props;
    const { city, records } = this.state;
    const showHideClassName = show ? "record-form__menu display-block" : "record-form__menu display-none";

    return (
      <div className="blur__background">
      <div className={showHideClassName}>
        <div className="menu__header">
          <div className="menu__header--name">
            
            {isEditing 
            ? "Редактирование записи на тренинг"
            : "Создайте запись на тренинг"
            }
          </div>
          <div
            className="menu__header--close-button"
            onClick={() => handleClose()}
          />
        </div>
        <form className="menu__form" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="menu__form--main">
            <div className="main__city">
              <div className="label">
                Город
              </div>
              <input
                type="text"
                name="city" 
                id="city"
                className="city text-input"
                placeholder="Киев"
                value={city}
                onChange={e => this.setState({city: e.target.value})}
              />
            </div>
            <div className="main__window">
              <div className="label">
                Окна записи 
              </div>
              <div className="main__window--list">
                {
                  this.state.records.map((item,index) => {
                    return (
                      <>
                        <input
                          type="date"
                          name="date_from"
                          id="date_from"
                          className="text-input date-input"
                          placeholder="Начало тренинга"
                          value={records[index].date_from}
                          onChange={e => this.changeDateFrom(e, index)}
                        />
                        <input
                          type="date"
                          name="date_to" 
                          id="date_to" 
                          className="text-input date-input"
                          placeholder="Окончание тренинга"
                          value={records[index].date_to}
                          onChange={e => this.changeDateTo(e, index)}
                        />
                        <div
                          className="window__delete-button"
                          onClick={() => this.deleteRecord(index, item.id)}
                        >
                        </div>
                      </>
                    );
                  })
                }
              </div>
              <div className="main__window--control">
                <div
                  className="add-window"
                  onClick={() => this.addRecord()}
                >
                  Добавить окна записи +
                </div>
              </div>
            </div>
          </div>
          <div className="menu__form--control">
            {
              isEditing
              ? <button
                  type="button"
                  className="button delete-button"
                  onClick={() => this.handleDelete()}
                >Удалить</button>
              : <button
                  type="button"
                  className="button cancel-button"
                  onClick={() => this.handleReset()}
                > 
                  Отменить 
                </button>
            }
            <button type="submit" className="button submit-button">
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </div>
    );
  }
}

export default RecordForm;