import React from "react";
import './ModuleProfile.scss';
import { LessonForm } from "./LessonForm/LessonForm";
import { fetchScientist } from '../../../API/Scientists/ScientistList';
import { getLessons } from '../../../API/Lessons';
import { getQuestions, createQuestion, deleteQuestion, updateQuestion, updateChoice } from "../../../API/Questions";
import { deleteModule } from "../../../API/Modules";

class ModuleProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      teacher: {},
      downloadedLessons: [],
      createLessons: [],
      formIsVisible: false,
      isEditing: false,
      deletionIds: [],
      lesson: {},
      tests: [],
    }
  }
  

  componentDidMount() {
    this.downloadInfo();
    this.downloadQuestions();
  }

  handleSave() {
    const { deletionIds, tests } = this.state;
    if (this.props.course.is_published) {
      alert('Нельзя изменять опубликованый курс');
      return;
    }

    for (let i = 0; i < tests.length; i++) {
      const result = tests[i].choices.find(item => item.is_true === true);
      if (!result) {
        alert(`В вопросе №${i + 1} не отмечен правильный ответ.`);
        return;
      }
    }

    this.uploadQuestions();
    this.updateQuestions();

    for (const id of deletionIds) {
      const response = deleteQuestion(id);
      response.then(
        data => {

          if (data.status === 403) {
            throw new Error('Менеджерам нельзя удалять вопросы.');
          } 

          if (!data.ok) {
            throw new Error('Не удалось удалить вопрос');
          }
        }
      ).catch(alert);
    }

    this.props.openModules(this.props.course);
  }

  handleDelete() {
    if (this.props.course.is_published) {
      alert('Нельзя изменять опубликованый курс');
      return;
    }

    if (!window.confirm('Вы уверены что хотите удалить курс?')) {
      return;
    }

    const response = deleteModule(this.props.module.id);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('Не удалось удалить модуль');
        }
        this.props.handleClose();
      }
    ).catch(alert);
  }

  uploadQuestions() {
    const { tests } = this.state;

    for (const item of tests) {
      if ('id' in item) {
      } else {
        const createResponse = createQuestion(item);
        createResponse.then(
          data => {

            if (data.status === 403) {
              throw new Error('Менеджерам нельзя создавать новые вопросы.');
            }

            if (!data.ok) {
              throw new Error('Не удалось создать вопрос');
            }
          }
        ).catch(alert);
      }
    }
  }

  updateQuestions() {
    const { tests } = this.state;

    const tempArr = tests.filter(item => {
      if ('id' in item) {
        return true;
      }

      return false;
    })

    for (const question of tempArr) {
      const questionUpdate = {
        question_text: question.question_text,
      };

      const questionResponse = updateQuestion(question.id, questionUpdate);
      questionResponse.then(
        data => {

          if (data.status === 403) {
            throw new Error('Менеджерам нельзя обновлять вопросы.');
          } 

          if (!data.ok) {
            throw new Error('Не удалось обновить вопрос');
          }
        }
      ).catch(alert);

      for (const choice of question.choices) {
        const choiceUpdate = {
          "choice_text": choice.choice_text,
          "is_true": choice.is_true,
        }

        const choiceResponse = updateChoice(choice.id, choiceUpdate);
        choiceResponse.then(
          data => {

            if (data.status === 403) {
              throw new Error('Менеджерам нельзя обновлять вопросы.');
            }

            if(!data.ok) {
              throw new Error('Не удалось обновить вопрос');
            }
          }
        ).catch(alert);
      }
    }
  }

  downloadInfo() {
    const { module } = this.props;

    const response = fetchScientist(`${this.props.module.teacher}/`);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('Не удалось загрузить преподавателя');
        }
        return data.json();
      }
    ).then(
      dataJSON => {
        this.setState({teacher: dataJSON});
      }
    ).catch(alert);

    const lessonsResponse = getLessons(this.props.module.id);
    lessonsResponse.then(
      data => {
        if (!data.ok) {
          throw new Error('Не удалось загрузить уроки');
        }
        return data.json();
      }
    ).then(
      dataJSON => {
        const count = module.amount_of_lessons - dataJSON.length;
        const createArr = [];
        for (let i = 0; i < count; i++) {
          createArr.push({});
        }
        this.setState({
          downloadedLessons: dataJSON,
          createLessons: createArr,
        });
      }
    ).catch(alert);
  }

  downloadQuestions() {
    const { module } = this.props;

    const response = getQuestions();
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('Не удалось загрузить тесты');
        }
        return data.json();
      }
    ).then(
      dataJSON => {
        const questionsArr = dataJSON.filter(item => item.module === module.id);
        this.setState({tests: questionsArr});
      }
    ).catch(alert);
  }

  addQuestion() {
    const tempArr = this.state.tests;
    tempArr.push(
      {
        module: this.props.module.id,
        question_text: '',
        choices: [
          {
            "choice_text":"",
            "is_true": false,
          },
          {
            "choice_text":"",
            "is_true": false,
          },
          {
            "choice_text":"",
            "is_true": false,
          },
          {
            "choice_text":"",
            "is_true": false,
          },
        ],
      }
    );
    this.setState({tests: tempArr});
  }

  deleteQuestion(index) {
    const tempArr = this.state.tests;
    const deletedQuestion = tempArr.splice(index,1);

    if ('id' in deletedQuestion[0]) {
      const idArr = this.state.deletionIds;
      idArr.push(deletedQuestion[0].id);
      this.setState({
        tests: tempArr,
        deletionIds: idArr,
      });
    } else {
      this.setState({tests: tempArr});
    }
  }

  handleQuestionChange(e,qIndex) {
    const tempArr = this.state.tests;
    tempArr[qIndex].question_text = e.target.value;
    this.setState({tests: tempArr});
  }

  handleAnswerChange(e,qIndex,aIndex) {
    const tempArr = this.state.tests;
    tempArr[qIndex].choices[aIndex].choice_text = e.target.value;
    this.setState({tests: tempArr});
  }

  handleCheckboxChange(e,qIndex,aIndex) {
    const tempArr = this.state.tests;
    tempArr[qIndex].choices[aIndex].is_true = e.target.checked;
    this.setState({tests: tempArr});
  }

  showCreationForm() {

    if(this.props.course.is_published) {
      alert('Нельзя изменить опубликованный курс.');
      return;
    }

    this.setState(() => ({
      isEditing: false,
      lesson: {},
      formIsVisible: true,
    }))
  }

  showEditForm(lessonObj) {

    if(this.props.course.is_published) {
      alert('Нельзя изменить опубликованный курс.');
      return;
    }

    this.setState(() => ({
      isEditing: true,
      lesson: lessonObj,
      formIsVisible: true,
    }));
  }

  hideForm() {
    this.setState(() => ({formIsVisible: false}));
    this.downloadInfo();
  }
  
  render() {
    const { teacher } = this.state;

    return (
      <div className="module-profile__page">
        {
          this.state.formIsVisible &&
          <LessonForm
            handleClose={this.hideForm.bind(this)}
            lesson={this.state.lesson}
            isEditing={this.state.isEditing}
            module={this.props.module}
          />
        }
        <div className="page__header">
          <div className="page__header--nav">
            <div className="title">
              {this.props.module.title}
            </div>
            <div className="breadcrumbs">
              <span
                className="breadcrumbs--link"
                onClick={() => this.props.openModules(this.props.course)}

              >Онлайн обучение / Курсы / Модули</span> / {this.props.module.title}
            </div>
          </div>
          <div className="page__header--control">
            <button
              className="delete-button button"
              onClick={() => this.handleDelete()}
            >
              Удалить
            </button>
            <button
              className="save-button button"
              onClick={() => this.handleSave()}
            >
              Сохранить
            </button>
          </div>
        </div>
        <div className="page__info">
          <div className="page__info--main info">
            <div className="info__title">
              {this.props.module.title}
            </div>
            <div className="info__description">
              {this.props.module.description}
            </div>
          </div>
          <div className="page__info--teacher info">
            <div
              className="teacher__photo"
              style={{backgroundImage: `url(${teacher.image})`}}
            ></div>
            <div className="teacher__info">
              <div className="teacher__info--name">
                {teacher.name}
              </div>
              <div className="teacher__info--date">
                {`${teacher.date}`.split('-').reverse().join('.')}
              </div>
            </div>
          </div>
        </div>
        <div className="page__lessons">
          <div className="page__lessons--title">
            Уроки:
          </div>
          <div className="page__lessons--list">
            {
              this.state.downloadedLessons.map((item, index) => {
                return(
                  <div className="lesson-card card" key={item.id}>
                    <div className="lesson-card__title">
                      {item.title}
                    </div>
                    <div className="lesson-card__wrapper">
                      <div className="lesson-card__number">
                        Урок {index + 1}
                      </div>
                      <div
                        className="lesson-card__edit-button"
                        onClick={() => this.showEditForm(item)}
                      >
                      </div>
                    </div>
                  </div>
                )
              })
            }
            {
              this.state.createLessons.map((item,index) => {
                return (
                  <div
                    className="lesson-card--create card"
                    onClick={() => this.showCreationForm()}
                  >
                    <div className="create-card__wrapper">
                      <div className="create-card__icon"></div>
                      <div className="create-card__text">
                        Добавить урок
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="page__tests">
          <div className="page__tests--title">
            Тест:
          </div>
          <div className="page__tests--wrapper">
            {
              this.state.tests.map((question, qIndex) => {
                return(
                  <div className="question">
                    <div className="question__header">
                      <div className="question__header--name">
                        {`Вопрос №${qIndex + 1}`}
                      </div>
                      <div
                        className="question__header--delete-button"
                        onClick={() => this.deleteQuestion(qIndex)}
                      ></div>
                    </div>
                    <div className="question__form">
                      <input
                        type="text"
                        className="question-input"
                        placeholder="Напишите вопрос"
                        value={question.question_text}
                        onChange={(e) => this.handleQuestionChange(e,qIndex)}
                      />
                      <div className="answer-wrapper">
                        {
                          question.choices.map((answer, aIndex) => {
                            return(
                              <div className="answer-input">
                                <input
                                  type="checkbox"
                                  className="answer-input__checkbox"
                                  checked={answer.is_true}
                                  onChange={(e) => this.handleCheckboxChange(e,qIndex,aIndex)}
                                />
                                <input
                                  type="text"
                                  className="answer-input__text"
                                  placeholder="Напишите ответ"
                                  value={answer.choice_text}
                                  onChange={(e) => this.handleAnswerChange(e,qIndex,aIndex)}
                                />
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div
            className="page__tests--add-button"
            onClick={() => this.addQuestion()}
          >
            Добавить еще вопрос
            <div className="add-icon"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default ModuleProfile;