import React from "react";
import './LoginScreen.scss';
import classNames from "classnames";
import { login, sendCode } from "../../API/Login";
import AdminToken from "../../API/AdminToken";

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      phone: '',
      password: '',
      isPasswordShown: false,
      wrongCredentials: false,
    }
  }

  changePasswordVisibility() {
    this.setState((oldState) => ({isPasswordShown: !oldState.isPasswordShown}));
  }

  signIn() {
    const {phone, password} = this.state;
    const formData = new FormData();

    if (phone.length !== 12 || isNaN(phone)) {
      alert('Введите корректный номер телефона в формате 380999999999');
      return;
    }

    if(password.length !== 6 || isNaN(password)) {
      alert('Введите корректный пароль в формате 999999');
      this.setState({wrongCredentials: true});
      return;
    }

    if (phone[0] === '+') {
      formData.append('phone_number', phone.split('+').join(''));
    } else {
      formData.append('phone_number', phone);
    }

    formData.append('code', password);

    const response = login(formData);
    response.then(
      data => {
        if (data.status === 403) {
          return data.json();
        }
        if (!data.ok) {
          throw new Error('Не удалось войти в аккаунт');
        }
        return data.json()
      }
    ).then(
      dataJSON => {
        if ('detail' in dataJSON) {
          this.setState({wrongCredentials: true});
        } else {
          AdminToken.value = `Token ${dataJSON.token}`
          this.props.changeSignIn();
        }
      }
    ).catch(alert);
  }

  sendPassword() {
    const { phone } = this.state;
    if (phone.length !== 12) {
      alert('Введите корректный номер телефона.');
      return;
    } else {
      const formData = new FormData();
      formData.append('phone_number', phone);
      const response = sendCode(formData);
      response.then(
        data => {
          if (!data.ok) {
            throw new Error('Не удалось отправить пароль.');
          }
          alert('На ваш номер был отправлен новый пароль.');
        }
      ).catch(alert);
    }
  }

  render() {
    const {phone, password, isPasswordShown, wrongCredentials} = this.state;
    return (
      <div className="login__page">
        <div className="login__wrapper">
          <div className="login__form">
            <div className="login__form--logo"></div>
            <div className="login__form--title">
              Вход в админ панель
            </div>
            <div className="login__form--inputs">
              <div className="login__wrapper">
                <div className="login__icon"></div>
                <input
                  type="text"
                  className="login"
                  autoComplete="off"
                  placeholder="Ваш логин"
                  value={phone}
                  onChange={(e) => {
                    if (this.state.wrongCredentials) {
                      this.setState({wrongCredentials: true})
                    }
                    this.setState({phone: e.target.value})
                  }}
                />
              </div>
              <div className="password__wrapper">
                <div
                  className={classNames("password__icon", {'icon--wrong-credentials': wrongCredentials})}
                ></div>
                <input
                  type={isPasswordShown ? "text" : "password"}
                  className={classNames("password",{'password-visible': isPasswordShown, 'password--wrong-credentials': wrongCredentials})}
                  autoComplete="off"
                  value={password}
                  placeholder='Ваш пароль'
                  onChange={(e) => {
                    if (wrongCredentials) {
                      this.setState({wrongCredentials: false});
                    }
                    this.setState({password: e.target.value});
                  }}
                />
                <div
                  className={classNames('password-button',{'button-visible': isPasswordShown})}
                  onClick={() => this.changePasswordVisibility()}
                ></div>
              </div>
            </div>
            <div
              className="login__form--restore"
              onClick={() => this.sendPassword()}
            >
              Забыли пароль?
            </div>
            <div
              className="login__button"
              onClick={() => this.signIn()}
            >
              Войти
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginScreen;