import {React, useState} from "react";
import './CreationForm.scss';
import { createScientist } from "../../../API/Scientists/ScientistList";
import classNames from "classnames";

const CreationForm = ({handleClose, show}) => {
  const showHideClassName = show ? "creation-form__menu display-block" : "creation-form__menu display-none";
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [isTeacher, setIsTeacher] = useState(false);
  const [isGovernmentMember, setIsGovernmentMember] = useState(false);
  const [isAcademicCouncil, setIsAcademicCouncil] = useState(false);
  const [fullDesc, setFullDesc] = useState('');
  const [fileObj,setFileObj] = useState({});
  const [imagePreview, setImagePreview] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!('file' in fileObj)) {
      alert('Вставьте картинку.');
      return;
    }

    if (!isTeacher && !isAcademicCouncil && !isGovernmentMember) {
      alert('Не выбрано ни одной категории.');
      return;
    }

    const formData = new FormData();
    formData.append('name',`${firstName} ${secondName}`);
    formData.append('image',fileObj.file);
    formData.append('short_desc',shortDesc);
    formData.append('description',fullDesc);
    formData.append('is_teacher',isTeacher);
    formData.append('goverment_member',isGovernmentMember);
    formData.append('academic_council',isAcademicCouncil);

    let result = createScientist(formData);
      result.then (
        (data) => {
          if (!data.ok) {
            throw new Error('Не удалось загрузить научного деятеля');
          }
          clearForm();
          handleClose();
      }
    ).catch(alert);  
  }

  const handleFileUpload = (event) => {
    const imgExtensions = 'jpeg.jpg.png';
    const imageName = event.target.files[0].name;
    const imageExtension = imageName.split('.').pop().toLowerCase();
    if (!imgExtensions.includes(imageExtension)) {
      alert('Прикрепите картинку');
      return;
    }
    const fileField = event.target.files[0];
    const filePreview = URL.createObjectURL(event.target.files[0]);
    setImagePreview(filePreview);
    setFileObj({
      file: fileField,
    });
  }

  function clearForm() {
    setFirstName('');
    setSecondName('');
    setShortDesc('');
    setIsTeacher(false);
    setIsGovernmentMember(false);
    setIsAcademicCouncil(false);
    setFullDesc('');
  }

  return(
    <div className="blur__background">
      <div className={showHideClassName}>
        <div className="menu__header">
          <div className="menu__header--name">
            Добавьте научного деятеля
          </div>
          <div
            className="menu__header--close-button"
            onClick={() => handleClose()}
          />
        </div>
        <form action="POST" className="menu__form" onSubmit={(e) => handleSubmit(e)}>
        <div className="menu__form--main">
            <div className="form__image">
              <div className="label">
                Фото деятеля
              </div>
              <label
                htmlFor="image-upload"
                className="image-upload"
                style={{backgroundImage: !!imagePreview ? `url(${imagePreview})` : 'none'}}
              >
                <div className={classNames('plus-icon',{hidden: !!imagePreview})}></div>
              </label>
              <input
                type="file"
                name="scientist__image"
                id="image-upload"
                className="image-upload"
                onChange={(e) => handleFileUpload(e)}
              />
            </div>
            <div className="form__info">
              <div className="form__info--full-name">
                <div className="full-name__wrapper">
                  <label htmlFor="first-name" className="label">
                    Имя
                  </label>
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    className="first-name text-input"
                    placeholder="Впишите имя"
                    required
                    autoComplete="off"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="full-name__wrapper">
                  <label htmlFor="second-name" className="label">
                    Фамилия
                  </label>
                  <input
                    type="text" 
                    name="second-name"
                    id="second-name"
                    className="second-name text-input"
                    placeholder="Впишите фамилию"
                    required
                    autoComplete="off"
                    value={secondName}
                    onChange={(e) => setSecondName(e.target.value)}
                  />
                </div>
              </div>
              <div className="form__info--short-desc">
                <label htmlFor="short-desc" className="label">
                  Краткое описание
                </label>
                <input
                  type="text"
                  name="short-desc"
                  id="short-desc"
                  className="short-desc text-input"
                  placeholder="Стаж, деятельность, ученое звание"
                  required
                  autoComplete="off"
                  value={shortDesc}
                  onChange={(e) => setShortDesc(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="menu__form--categories">
            <div className="categories__header">
              Выберите категорию для научного деятеля
            </div>
            <div className="categories__wrapper">
              <input
                type="checkbox"
                name="government-checkbox"
                id="government-checkbox"
                className="checkbox"
                checked={isGovernmentMember}
                onChange={(e) => setIsGovernmentMember(e.target.checked)}
              />
              <div className="label">
                Члены правления
              </div>
              <input
                type="checkbox"
                name="teacher-checkbox"
                id="teacher-checkbox"
                className="checkbox"
                checked={isTeacher}
                onChange={(e) => setIsTeacher(e.target.checked)}
              />
              <div className="label">
                Преподователи
              </div>
              <input
                type="checkbox"
                name="council-checkbox"
                id="council-checkbox"
                className="checkbox"
                value={isAcademicCouncil}
                onChange={(e) => setIsAcademicCouncil(e.target.checked)}
              />
              <div className="label">
                Ученый совет
              </div>
            </div>
          </div>
          <div className="menu__form--full-desc">
            <div className="full-desc__header">
              Полное описание деятельности 
            </div>
            <textarea
              name="full-desc"
              id="full-desc"
              className="full-desc text-input"
              placeholder="Напишите краткое, которое характеризует научного деятеля"
              required
              autoComplete="off"
              value={fullDesc}
              onChange={(e) => setFullDesc(e.target.value)}
            >

            </textarea>
          </div>
          <div className="menu__form--control">
            <button
              type="reset"
              className="button cancel-button"
              onClick={() => handleClose()}
            >
              Отменить
            </button>
            <button
              type="submit"
              className="button submit-button"
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}


export default CreationForm;