import React from "react";
import './ModulesList.scss';
import { getModules, editModule } from "../../../API/Modules";
import Pagination from "../../Pagination/Pagination";
import classNames from "classnames";
import ModuleForm from "./ModuleForm/ModuleForm";

const PageSize = 10;

class ModulesList extends React.Component {
  state = {
    searchQuery: '',
    currentPage: 1,
    formIsVisible: false,
    modulesArr: [],
    isEditing: false,
    module: {},
  }

  setCurrentPage(value) {
    this.setState({currentPage: value});
  }

  setSearchQuery(event) {
    this.setState(() => ({searchQuery: event.target.value}));
  }

  showCreationForm() {
    this.setState(() => {return({
      isEditing: false,
      module: {},
      formIsVisible: true
    })})
  }

  showEditForm(moduleObj) {
    this.setState(() => ({
      isEditing: true,
      module: moduleObj,
      formIsVisible: true,
    }));
  }

  hideForm() {
    this.setState(() => {return({formIsVisible: false})});
    this.downloadModules();
  }

  componentDidMount() {
    this.downloadModules();
  }

  downloadModules () {
    const result = getModules();
    result.then(
      data => {
        if (!data.ok) {
          throw new Error('Не удалось загрузить список модулей');
        }
        return data.json();
      }
    ).then(dataJSON => {
      this.setState(() => ({modulesArr: dataJSON.filter(item => item.course === this.props.course.id)}));
    })
    .catch(alert);
  }
  
  changeVisibility(module) {
    if (this.props.course.is_published) {
      alert('Нельзя редактировать опубликованый курс');
      return;
    }
    
    const formData = new FormData();
    formData.append('is_visible',!module.is_visible);

    const response = editModule(module.id, formData);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('Не удалось изменить видимость модуля');
        }
        this.downloadModules();
      }
    ).catch(alert);
  }

  sortBySearchQuery() {
    return this.state.modulesArr.filter(module => module.title.toLowerCase().includes(this.state.searchQuery.toLowerCase()));
  }

  render() {
    const {modulesArr, currentPage, formIsVisible} = this.state;
    const filteredArr = this.sortBySearchQuery();
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = filteredArr.slice(firstPageIndex, lastPageIndex);
    const firstNumber = firstPageIndex + 1;
    let lastNumber = lastPageIndex;
    if (lastPageIndex <= filteredArr.length) {
      lastNumber = lastPageIndex;
    } else {
      lastNumber = filteredArr.length;
    }
    return(
      <div className="modules-list__page">
        <div className='page__header'>
          <div className='info'>
            <h2 className='info__title'>Модули</h2>
            <div className="info__breadcrumbs">
              <span
                className="info__breadcrumbs--back"
                onClick={() => this.props.openCourses()}
              >
                Онлайн обучение / Курсы </span> / Модули
            </div>
          </div>
          <div className='navigation'>
            <input
              type="search"
              placeholder='Поиск по названию'
              className='navigation__search-bar'
              value={this.state.searchQuery}
              onChange={(event) => this.setSearchQuery(event)}
            />
            <button 
              className='navigation__add-button'
              onClick={() => {this.showCreationForm()}}
            >
              Добавить
            </button>
            {
              formIsVisible &&
              <ModuleForm
                show={this.state.formIsVisible}
                handleClose={this.hideForm.bind(this)}
                isEditing={this.state.isEditing}
                course={this.props.course}
                module={this.state.module}
              />
            }
          </div>
        </div>
        <div className="page__pagination">
          <div className="page__table">
            <div className="grid__table table__head">
              <div className="head__number head">№</div>
              <div className="head__name head">Название модуля</div>
              <div className="head__date head">Дата публикации</div>
              <div className="head__count head">Кол-во уроков</div>
              <div className="head__hide head">Скрыть</div>
              <div className="head__edit head">Ред.</div>
            </div>
            {currentData.map(
              (item) => {return(
                <div className="grid__table row" key={item.module_number}>
                  <div className="table__item number">{item.module_number}</div>
                  <div
                    className="table__item name"
                    onClick={() => this.props.openModuleProfile(item)}
                  >
                    {item.title}
                  </div>
                  <div className="table__item date">{item.created_at.split('-').reverse().join('.')}</div>
                  <div className="table__item count">{item.amount_of_lessons}</div>
                  <div className="table__item hide__item">
                    <div
                      className={classNames('hide-button',{'hide-button--hidden': !item.is_visible})}
                      onClick={() => this.changeVisibility(item)}></div>
                  </div>
                  <div className="table__item edit__item">
                    <div
                      className="edit-button"
                      onClick={() => {this.showEditForm(item)}}></div>
                  </div>
                </div>
              )}
            )}
          </div>
          <div className="pagination__nav">
            <div className='pagination__nav--number'>
              {`${firstNumber}-${lastNumber} из ${filteredArr.length}`}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={filteredArr.length}
              pageSize={PageSize}
              onPageChange={page => this.setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ModulesList;