import React from "react";
import './CoursesForm.scss';
import classNames from "classnames";
import { createCourse, editCourses, deleteCourse } from "../../../../API/Courses";

class CoursesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      isEditing: this.props.isEditing,
      course: this.props.course,
      photo: {},
      title: '',
      videoLink: '',
      moduleCount: '',
      description: '',
      photoPreview: '',
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { title, description, moduleCount,videoLink,photo} = this.state;

    if (!this.state.isEditing && !('image' in photo)) {
      alert ('Вставьте картинку.');
      return;
    }

    const formData = new FormData();
    formData.append('title',title);
    formData.append('description',description);
    formData.append('amount_of_modules',moduleCount);
    formData.append('video',videoLink);
    formData.append('image',photo.image);

    if (typeof formData.get('image') === 'string') {
      formData.delete('image');
    }

    if (this.state.isEditing) {
      if (this.props.course.is_published) {
        alert('Нельзя редактировать опубликованный курс');
        this.props.handleClose();
        return;
      }

      if (formData.get('image') === undefined) {
        formData.delete('image');
      }

      const result = editCourses(this.state.course.id, formData);
      result.then(
        data => {
          if (data.status === 403) {
            throw new Error('Менеджерам нельзя редактировать курсы.');
          }    

          if (!data.ok) {
            throw new Error('Не удалось отредактировать курс');
          }
          this.props.handleClose();
          return [];
        }
      ).catch(alert);

    } else {
      const response = createCourse(formData);
      response.then(
        data => {

          if (data.status === 403) {
            throw new Error('Менеджерам нельзя создавать новые курсы.');
          }

          if (data.status === 400) {
            return data.json();
          }
          
          if (!data.ok) {
            throw new Error('Не удалось создать курс');
          }
          this.props.handleClose();
          return {};
        }).then(
          dataJSON => {
            switch(true) {
              case ('video' in dataJSON):
                alert('Введите действительную ссылку на видео.');
                break;
              default:
                break;
            }
          }
        ).catch(alert);
    }
  }

  componentDidMount() {
    if (this.state.isEditing) {
      const { course } = this.props;
      this.setState({
        title: course.title,
        description: course.description,
        videoLink: course.video,
        moduleCount: course.amount_of_modules,
      });
    } else {
      this.setState({course: {
        is_published: false,
      }});
    }
  }

  handlePhotoUpload(e) {
    const imgExtensions = 'jpeg.jpg.gif.tif.png.raw';
    const imageName = e.target.files[0].name;
    const imageExtension = imageName.split('.').pop();
    if (!imgExtensions.includes(imageExtension.toLowerCase())) {
      alert('Прикрепите картинку');
      return;
    }
    this.setState(() => ({
      photo: {image: e.target.files[0]},
      photoPreview: URL.createObjectURL(e.target.files[0]),
    }));
  }

  handleDelete() {
    const result = deleteCourse(this.props.course.id);
    result.then(
      data => {

        if (data.status === 403) {
          throw new Error('Менеджерам нельзя удалять курсы.');
        }  

        if (!data.ok) {
          throw new Error('Не удалось удалить курс');
        }
        this.props.handleClose();
      }
    ).catch(alert);
  }

  render() {
    const {show, isEditing, videoLink, title, moduleCount, description, photoPreview} = this.state;
    const showHideClassName = show ? "courses-form__menu display-block" : "courses-form__menu display-none";
    return(
      <div className="blur__background">
      <div className={showHideClassName}>
        <div className="menu__header">
          <div className="menu__header--name">
            {isEditing 
              ? "Редактирование курса"
              : "Добавьте курс"
            }
          </div>
          <div
            className="menu__header--close-button"
            onClick={() => this.props.handleClose()}
          />
        </div>
        <form className="menu__form" onSubmit={(e) => this.handleSubmit(e)}>
        <div className="menu__form--main">
            <div className="form__image">
              <div className="label">
                Фото для обложки
              </div>
              <label
                htmlFor="image-upload"
                className="image-upload"
                style={{backgroundImage: !!photoPreview ? `url(${photoPreview})` : 'none'}}
              >
                <div className={classNames('plus-icon',{hidden: !!photoPreview})}></div>
              </label>
              <input
                type="file"
                name="scientist__image"
                id="image-upload"
                className="image-upload"
                disabled={this.state.course.is_published}
                onChange={(event) => this.handlePhotoUpload(event)}
              />
            </div>
            <div className="form__info">
              <div className="form__info--course-name">
                <label htmlFor="course-name" className="label">
                  Название курса
                </label>
                <input
                  type="text"
                  name="course-name"
                  id="course-name"
                  className="course-name text-input"
                  placeholder="Напишите название курса"
                  required={!isEditing}
                  disabled={this.state.course.is_published}
                  autoComplete="off"
                  value={title}
                  onChange={(event) => this.setState({title: event.target.value})}
                />
                <label htmlFor="video-link" className="label">
                  Прикрепите ссылку на видео
                </label>
                <input
                  type="text"
                  name="video-link"
                  id="video-link"
                  className="video-link text-input"
                  required={!isEditing}
                  autoComplete="off"
                  disabled={this.state.course.is_published}
                  value={videoLink}
                  onChange={e => this.setState({videoLink: e.target.value})}
                />
              </div>
            </div>
          </div>
          <div className="menu__form--additional-info">
            <div className="full-desc__wrapper">
              <div className="full-desc__header">
                Описание курса
              </div>
              <textarea
                name="full-desc"
                id="full-desc"
                className="full-desc text-input"
                placeholder="Напишите краткое описание"
                required={!isEditing}
                disabled={this.state.course.is_published}
                autoComplete="off"
                value={description}
                onChange={(event) => this.setState({description: event.target.value})}
              >
              </textarea>
            </div>
            <div className="additional-info__wrapper">
              <label htmlFor="count-number" className="label">
                  Кол-во модулей
                </label>
                <input
                  type="number"
                  name="count-number"
                  id="count-number"
                  className="count-number text-input"
                  placeholder="Напишите число"
                  disabled={this.state.course.is_published}
                  required
                  autoComplete="off"
                  value={moduleCount}
                  onChange={(event) => this.setState({moduleCount: event.target.value})}
                />
            </div>
          </div>
          <div className="menu__form--control">
            {
              isEditing
              ? <button
                  type="button"
                  className="button delete-button"
                  onClick={() => this.handleDelete()}
                >Удалить</button>
              : <button
                  type="reset"
                  className="button cancel-button"
                  onClick={() => this.props.handleClose()}
                > Отменить </button>
            }
            <button type="submit" className="button submit-button">
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </div>
    );
  }
}

export default CoursesForm;