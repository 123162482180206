import React from "react";
import './LessonForm.scss';
import { 
  createLesson,
  deleteLesson,
  editLesson,
  createFile,
  createVideo,
  editVideo,
  deleteFile,
  deleteVideo,
} from "../../../../API/Lessons";

export class LessonForm extends React.Component {
  state = {
    title: '',
    description: '',
    videosArr: [],
    filesArr: [],
  }

  componentDidMount() {
    if (this.props.isEditing) {
      this.setData();
    }
  }

  setData() {
    const {title, description, lesson_files, lesson_videos} = this.props.lesson;
    this.setState({
      title: title,
      description: description,
      videosArr: lesson_videos,
      filesArr: lesson_files,
    });
  }

  handleFileUpload(e) {
    const fileName = e.target.files[0].name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if (fileExtension !== 'pdf') {
      alert('Прикрепите файл формата PDF');
      return;
    }
    let tempArr = [...this.state.filesArr];
    tempArr.push({file: e.target.files[0]});
    this.setState(() => ({
      filesArr: tempArr,
    }));
  }

  handleFileDelete(index, item) {
    if (item.id) {
      const response = deleteFile(item.id);
      response.then(
        data => {
          if (!data.ok) {
            throw new Error('Не удалось удалить файл с сервера');
          }
        }
      ).catch(alert);
    }
    let tempArr = [...this.state.filesArr];
    tempArr.splice(index,1);
    this.setState(() => ({
      filesArr: tempArr,
    }));
  }

  handleVideoUpload(id) {
    let tempArr = [...this.state.videosArr];
    tempArr.push({
      title: '',
      video: '',
    });
    this.setState(() => ({
      videosArr: tempArr,
    }));
  }

  handleVideoChange(e,index) {
    let tempArr = this.state.videosArr;
    if (e.target.name === 'video-title') {
      tempArr[index].title = e.target.value;
    } else {
      tempArr[index].video = e.target.value;
    }
    this.setState({videosArr: tempArr});
  }

  handleVideoDelete(index,item) {
    if (item.id) {
      const response = deleteVideo(item.id);
      response.then(
        data => {
          if (!data.ok) {
            throw new Error('Не удалось удалить видео с сервера');
          }
        }
      ).catch(alert);
    }
    let tempArr = this.state.videosArr;
    tempArr.splice(index,1);
    this.setState({
      videosArr: tempArr,
    })
  }

  handleDelete() {
    const response = deleteLesson(this.props.lesson.id);
    response.then(
      data => {

        if (data.status === 403) {
          throw new Error('Менеджерам нельзя удалять уроки.');
        }  

        if (!data.ok) {
          throw new Error('Не удалось удалить урок');
        }
        this.props.handleClose();
      }
    ).catch(alert);
  }

  handleReset() {
    this.setState({
      title: '',
      description: '',
      videosArr: [],
      filesArr: [],
    });
  }

  handleSubmit() {
    if (this.props.isEditing) {
      this.handleEdit()
    } else {
      this.handleCreate();
    }
  }

  handleCreate() {
    const {title, description, videosArr, filesArr} = this.state;
    const formData = new FormData();
    formData.append('module', this.props.module.id);

    if (title.length === 0) {
      alert('Введите название урока.');
      return;
    } else {
      formData.append('title',title);
    }

    if (description.length === 0) {
      alert('Введите описание урока.');
      return;
    } else {
      formData.append('description',description);
    }

    if (videosArr.length === 0) {
      alert('Впишите ссылки на видео-материалы.');
      return;
    } else {
      formData.append('lesson_videos', JSON.stringify(videosArr));
    }

    if (filesArr.length === 0) {
      alert('Прикрепите PDF-материалы');
      return;
    } else {
      for (let item of filesArr) {
        formData.append('lesson_files',item.file);
      }
    }

    const response = createLesson(formData);
    response.then(data => {

      if (data.status === 403) {
        throw new Error('Менеджерам нельзя создавать новые уроки.');
      }

      if(data.status === 500) {
        return;
      }

      if (!data.ok) {
        throw new Error('Не удалось создать урок');
      }
      this.props.handleClose();
    }).catch(alert);
  }

  handleEdit() {
    const {title, description, videosArr, filesArr} = this.state;
    const formData = new FormData();
    formData.append('title',title);
    formData.append('description',description);

    const lessonResponse = editLesson(this.props.lesson.id,formData);
    lessonResponse.then(
      data => {

        if (data.status === 403) {
          throw new Error('Менеджерам нельзя изменять уроки.');
        } 

        if (!data.ok) {
          throw new Error('Не удалось обновить урок');
        }
      }
    ).catch(alert);
    //обновление названия и описания урока

    for (let file of filesArr) {
      if (!file.id) {
        const fileForm = new FormData();
        fileForm.append('lesson',this.props.lesson.id);
        fileForm.append('file',file.file);

        const fileResponse = createFile(fileForm);
        fileResponse.then(
          data => {
            if (!data.ok) {
              throw new Error('Не удалось загрузить файл');
            }
          }
        ).catch(alert);
      }
    }

    for (let video of videosArr) {
      const videoForm = new FormData();
      videoForm.append('title',video.title);
      videoForm.append('video',video.video);
      if (!video.id) {
        videoForm.append('lesson',this.props.lesson.id);
        const videoResponse = createVideo(videoForm);
        videoResponse.then(data => {
          if (!data.ok) {
            throw new Error('Не удалось сохранить ссылку на видео');
          }
        }).catch(alert);
      } else {
        const videoResponse = editVideo(video.id, videoForm);
        videoResponse.then(data => {
          if (!data.ok) {
            throw new Error('Не удалось изменить ссылку на видео');
          }
        }).catch(alert);
      }
    }

    this.props.handleClose();
    this.props.handleClose();
  }

  render() {
    const {isEditing, handleClose} = this.props;
    const {title, description} = this.state;
    return(
      <div className="blur__background">
        <div className="lesson-form__menu">
          <div className="menu__header">
            <div className="menu__header--name">
              
              {isEditing 
              ? "Редактирование урока"
              : "Добавьте урок"
              }
            </div>
            <div
              className="menu__header--close-button"
              onClick={() => handleClose()}
            />
          </div>
          <div className="menu__form">
            <div className="menu__form--main">
              <div className="label">
                Название урока
              </div>
              <input
                  type="text"
                  name="lesson-title"
                  id="lesson-title"
                  className="lesson-title text-input"
                  placeholder="Напишите название урока"
                  required={!isEditing}
                  autoComplete="off"
                  value={title}
                  onChange={(event) => this.setState({title: event.target.value})}
                />
              <div className="label">
                Описание урока
              </div>
              <textarea
                className="lesson-description text-input"
                name="lesson-description"
                id="lesson-description"
                placeholder="Напишите краткое описание урока"
                autoComplete="off"
                value={description}
                onChange={e => this.setState({description: e.target.value})}
              >
              </textarea>
            </div>
            <div className="menu__form--files">
              <div className="label__wrapper">
                <div className="label">
                  Прикрепите PDF-материалы
                </div>
                <label
                  htmlFor="file-upload"
                  className="file-upload__label"
                ></label>
                <input
                  type="file"
                  name="file-upload"
                  id="file-upload"
                  className="file-upload__input"
                  value={''}
                  onChange={(e) => {this.handleFileUpload(e);}}
                />
              </div>
              <div className="files__list">
                {
                  this.state.filesArr.map((item, index) => {
                    return(
                      <div className="file-info">
                        {
                          item.id
                          ? item.file.split('/').pop()
                          : item.file.name
                        }
                        <div
                          className="file-info__delete-button"
                          onClick={() => this.handleFileDelete(index,item)}
                        ></div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="menu__form--videos">
              <div className="label__wrapper">
                <div className="label">
                  Впишите ссылки на видео-материалы
                </div>
                <div
                  className="video-upload__label"
                  onClick={() => this.handleVideoUpload(this.state.videosArr.length)}
                ></div>
              </div>
              <div className="videos__list">
                {
                  this.state.videosArr.map((item, index) => {
                    return (
                      <div
                        className="list__item"
                        key={index}
                      >
                        <input
                          type="text"
                          className="text-input video-title"
                          name='video-title'
                          placeholder="Напишите название материала"
                          value={this.state.videosArr[index].title}
                          onChange={(e) => this.handleVideoChange(e,index)}
                        />
                        <input
                          type="text"
                          className="text-input video-link"
                          name='video-link'
                          placeholder="Впишите ссылку на видео урок"
                          value={this.state.videosArr[index].video}
                          onChange={(e) => this.handleVideoChange(e,index)}
                        />
                        <div
                          className="delete-button"
                          onClick={() => this.handleVideoDelete(index,item)}
                        ></div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="menu__form--control">
            {
              isEditing
              ? <button
                  type="button"
                  className="button delete-button"
                  onClick={() => this.handleDelete()}
                >Удалить</button>
              : <button
                  type="button"
                  className="button cancel-button"
                  onClick={() => this.handleReset()}
                > Отменить </button>
            }
            <button
              type="button"
              className="button submit-button"
              onClick={() => this.handleSubmit()}
            >
              Сохранить
            </button>
          </div>
          </div>
        </div>
      </div>
    );
  }
}