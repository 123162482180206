import React from "react";
import './UserProfile.scss';
import { getAvailableCourses } from '../../../API/Users';
import { getModules } from '../../../API/Modules';
import ModuleCard from "../ModuleCard/ModuleCard";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      object: this.props.object,
      availableCourses: [],
      modules: [],
      moduleResults: [],
      showModules: false,
      courseId: 1,
    }
  }

  componentDidMount() {
    this.downloadAvailableCourses(this.state.object.id);
  }

  downloadAvailableCourses(id) {
    const result = getAvailableCourses(id);
    result.then((data) => {
      if (!data.ok) {
        alert(data.status);
        throw new Error('Не удалось загрузить список курсов');
      }
      return data.json();
    }).then((dataJSON) => {
      this.setState(() => ({availableCourses: dataJSON}));
    }).catch(alert);
  }

  openModules(id = this.state.courseId) {
    this.setState(() => ({
      showModules: true,
      courseId: id,
    }));

    const response = getModules();
    response.then(data => {
      if (!data.ok) {
        throw new Error ('Не удалось загрузить модули');
      }
      return data.json();
    }).then(dataJSON => {
      const courseModules = dataJSON.filter(item => item.course === id);
      this.setState(() => ({modules: courseModules}))
    }).catch(alert);
  }

  refresh() {
    this.setState({modules: []});
    this.openModules();
  }

  render() {
    const {object, availableCourses, modules, moduleResults} = this.state;
    return(
      <div className="user-profile__page">
        <div className='page__header'>
          <div className='info'>
            <h2 className='info__title'>{object.user.name}</h2>
          </div>
          <div className="navigation" onClick={() => this.props.closeProfile()}>
          </div>
        </div>
        <div className="page__info">
          <div className="info__main-card card">
            <div
              className="main-card__photo"
              style={{backgroundImage:`url(${object.photo})`}}
            ></div>
            <div className="main-card__wrapper">
              <div className="main-card__email info-row">
                <div className="head">E-mail</div>
                {object.user.email}
              </div>
              <div className="main-card__phone info-row">
                <div className="head">Номер телефона</div>
                {object.user.phone_number}
              </div>
              <div className="main-card__city info-row">
                <div className="head">Город</div>
                Киев
              </div>
            </div>
          </div>
          <div className="info__additional-card card">
            <div className="additional-card__date info-row">
              <div className="head">Дата регистрации</div>
              {object.date_joined.split('-').reverse().join('.')}
            </div>
            <div className="additional-card__courses info-row">
              <div className="head">Кол-во доступных курсов</div>
              {this.state.availableCourses.length}
            </div>
          </div>
        </div>
        {
          this.state.availableCourses.length === 0
          ? ''
          : <div className="page__courses">
          {
            !this.state.showModules

            ? <>
              <div className="courses__title">
                Доступные курсы
              </div>
              <div className="courses__table">
                <div className="grid__table table__head">
                  <div className="head__number head">№</div>
                  <div className="head__name head">Название курса</div>
                  <div className="head__date head">Дата начала прохождения</div>
                  <div className="head__modules head">Кол-во модулей</div>
                </div>
                {
                  availableCourses.map((item) => {return(
                    <div
                      className="grid__table row"
                      onClick={(e) => this.openModules(item.course.id)}
                      key={item.course.id}
                    >
                      <div className="table__item">{item.course.id}</div>
                      <div className="table__item">{item.course.title}</div>
                      <div className="table__item">{item.date_started.split('-').reverse().join('.')}</div>
                      <div className="table__item">{item.course.amount_of_modules}</div>
                    </div>
                  )})
                }
              </div>
              </>
            : <>
                <div className="courses__title">
                  <span
                    className="courses__title--breadcrumb"
                    onClick={() => this.setState({showModules: false})}
                  >Доступные курсы / </span>
                  Все модули
                </div>
                <div className="modules__table">
                  <div className="grid__table table__head">
                    <div className="head__number head">№</div>
                    <div className="head__name head">Название модуля</div>
                    <div className="head__count head">Кол-во уроков</div>
                    <div className="head__results head">Результат</div>
                    <div className="head__view-test head">Просмотр теста</div>
                    <div className="head__status head">Статус проходимости</div>
                  </div>
                {
                  modules.map((item) => {return(
                    <ModuleCard
                      module={item}
                      userId={this.state.object.id}
                      moduleResult={moduleResults.find(result => result.module.id === item.id)}
                      key={item.id}
                      refresh={this.refresh.bind(this)}
                      courseID={this.state.courseId}
                    />
                  )})
                }
              </div>
              </>
          }
        </div>
        }
      </div>
    )
  }
}

export default UserProfile