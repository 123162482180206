import React from "react";
import './RecordCard.scss';
import classNames from "classnames";
import RecordForm from "../RecordForm/RecordForm";
import { changeTrainingVisibility } from "../../../API/Trainings";

class RecordCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formIsVisible: false,
    }
  }

  showForm() {
    this.setState(() => {return({formIsVisible: true})})
  }

  hideForm() {
    this.setState(() => {return({formIsVisible: false})})
    this.props.update();
  }

  editVisibility() {
    const {record} = this.props;
    const result = changeTrainingVisibility(record.id, !record.visibility);

    result.then((data) => {
      if (!data.ok) {
        throw new Error('Не удалось изменить видимость');
      }
      this.props.update();
    }).catch(alert);
  }

  render() {
    const { record } = this.props;
    return (
      <div className="record-card">
        <div className={classNames("record-card__main",{"record-card__main--disabled": !record.visibility})}>
          {`${record.id} - ${record.city}`}
        </div>
        <div className="record-card__extra">
          <div
            className={classNames("view-button",{'view-button--disabled': !record.visibility})}
            onClick={() => this.editVisibility()}
          ></div>
          <div
            className="edit-button"
            onClick={() => this.showForm()}
          ></div>
        </div>
        {
          this.state.formIsVisible && 
          <RecordForm
            show={this.state.formIsVisible}
            handleClose={this.hideForm.bind(this)}
            object={this.props.record}
            isEditing={true}
            id={this.props.record.id}
          />
        }
      </div>
    );
  }
}

export default RecordCard;