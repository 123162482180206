import API_DOMAIN from './CONFIG.js';

const BASE_URL = `${API_DOMAIN}`;

async function login(body) {
  const getToken = () => {
    return fetch (BASE_URL + 'users/login/', {
      method: "POST",
      mode: "cors",
      cache: "default",
      headers: {
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await getToken();
}

async function sendCode(body) {  
  const send = () => {
    return fetch(BASE_URL + 'users/code_send/', {
      method: "POST",
      mode: "cors",
      cache: "default",
      headers: {
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await send();
}

export { login, sendCode };
