import React from "react";
import './VideoForm.scss';
import { deleteVideo, createVideo, editVideo } from "../../../API/Videos";
import classNames from "classnames";

const VideoForm = ({handleClose, show, object, isEditing}) => {
  const showHideClassName = show ? "video-form__menu display-block" : "video-form__menu display-none";
  const [title,setTitle] = React.useState('');
  const [number,setNumber] = React.useState('');
  const [description,setDescription] = React.useState('');
  const [photoObj,setPhotoObj] = React.useState({});
  const [link,setLink] = React.useState('');
  const [photoPreview,setPhotoPreview] = React.useState('');
  const [formDataObj] = React.useState({formData: new FormData()});

  React.useEffect(() => {
    setData();
  },[isEditing]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const {formData} = formDataObj;
    formData.append('title',title);
    formData.append('number',number);
    formData.append('description',description);
    formData.append('link',link);
    formData.append('photo',photoObj.photo);

    if (!('photo' in photoObj) && !isEditing) {
      alert('Прикрепите фото.');
      return;
    }

    if (typeof formData.get('photo') === 'string') {
      formData.delete('photo');
    }

    if (isEditing) {
      const response = editVideo(formData, object.id);
      response.then(
        (data) => {

          if (data.status === 403) {
            throw new Error('Менеджерам нельзя редактировать видео лекции.');
          }  

          if (data.status === 400) {
            return data.json();
          }

          if (!data.ok) {
            throw new Error('Не удалость отредактировать запись');
          }
          handleClose();
          return {};
        }
      ).then(
        dataJSON => {
          switch(true) {
            case ('number' in dataJSON):
              alert('Видео с таким номером уже создано.');
              break;
            default:
              break;
          }
        }
      ).catch(alert);
    } else {
      const response = createVideo(formData);
      response.then((data) => {

        if (data.status === 403) {
          throw new Error('Менеджерам нельзя создавать новые видео лекции.');
        }

        if (data.status === 400) {
          return data.json();
        }

        if (!data.ok) {
          throw new Error('Не удалость добавить запись');
        }
        handleClose();
        return {};
      }).then(
        dataJSON => {
          switch(true) {
            case ('number' in dataJSON):
              alert('Видео с таким номером уже создано.');
              break;
            default:
              break;
          }
        }
      ).catch(alert);
    }
  }

  const handlePhotoUpload = (event) => {
    const imgExtensions = 'jpeg.jpg.gif.tif.png.raw';
    const imageName = event.target.files[0].name;
    const imageExtension = imageName.split('.').pop();
    if (!imgExtensions.includes(imageExtension.toLowerCase())) {
      alert('Прикрепите картинку');
      return;
    }
    setPhotoObj({photo: event.target.files[0]});
    setPhotoPreview(URL.createObjectURL(event.target.files[0]));
  }

  const handleDelete = () => {
    const result = deleteVideo(object.id);
    result.then((data) => {

      if (data.status === 403) {
        throw new Error('Менеджерам нельзя удалять видео лекции.');
      } 

      if (!data.ok) {
        throw new Error('Не удалость удалить запись');
      }
      handleClose(true);
    }).catch(alert);
  }

  const setData = () => {
    if (isEditing) {
      setTitle(object.title);
      setNumber(object.number);
      setDescription(object.description);
      setLink(object.link);
      setPhotoPreview(object.photo);
    }
  }

  return(
    <div className="blur__background">
      <div className={showHideClassName}>
        <div className="menu__header">
          <div className="menu__header--name">
            
            {isEditing 
            ? "Редактирование видео - лекции"
            : "Добавьте видео - лекцию"
            }
          </div>
          <div
            className="menu__header--close-button"
            onClick={() => handleClose()}
          />
        </div>
        <form className="menu__form" onSubmit={(e) => handleSubmit(e)}>
        <div className="menu__form--main">
            <div className="form__image">
              <div className="label">
                Фото для обложки
              </div>
              <label
                htmlFor="image-upload"
                className="image-upload"
                style={{backgroundImage: !!photoPreview ? `url(${photoPreview})` : `none`}}
              >
                <div className={classNames('plus-icon',{hidden: !!photoPreview})}></div>
              </label>
              <input
                type="file"
                name="scientist__image"
                id="image-upload"
                className="image-upload"
                required={!isEditing}
                onChange={(event) => handlePhotoUpload(event)}
              />
            </div>
            <div className="form__info">
              <div className="form__info--publication-link">
                <label htmlFor="publication-link" className="label">
                  Прикрепите ссылку на видео
                </label>
                <input
                  type="text"
                  name="publication-link"
                  id="publication-link"
                  className="publication-link text-input"
                  placeholder=""
                  required={!isEditing}
                  autoComplete="off"
                  value={link}
                  onChange={(event) => setLink(event.target.value)}
                />
              </div>
              <div className="form__info--publication-number">
                <label htmlFor="index-number" className="label">
                  Порядковый номер
                </label>
                <input
                  type="number"
                  name="index-number"
                  id="index-number"
                  className="index-number text-input"
                  placeholder="№ публикации"
                  required={!isEditing}
                  autoComplete="off"
                  value={number}
                  onChange={(event) => setNumber(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="menu__form--additional-info">
            <div className="additional-info__wrapper">
              <label htmlFor="publication-name" className="label">
                Название лекции
                </label>
                <input
                  type="text"
                  name="publication-name"
                  id="publication-name"
                  className="publication-name text-input"
                  placeholder="Напишите название лекции"
                  required={!isEditing}
                  autoComplete="off"
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
            </div>
            <div className="full-desc__wrapper">
              <div className="full-desc__header">
                Описание лекции
              </div>
              <textarea
                name="full-desc"
                id="full-desc"
                className="full-desc text-input"
                placeholder="Напишите краткое описание лекции"
                required={!isEditing}
                autoComplete="off"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              >
              </textarea>
            </div>
          </div>
          <div className="menu__form--control">
          {
              isEditing
              ? <button
                  type="button"
                  className="button delete-button"
                  onClick={() => handleDelete()}
                >Удалить</button>
              : <button
                  type="reset"
                  className="button cancel-button"
                  onClick={() => handleClose()}
                > Отменить </button>
            }
            <button type="submit" className="button submit-button">
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </div>
  );

}

export default VideoForm;