import AdminToken from "./AdminToken";
import API_DOMAIN from './CONFIG.js';

const BASE_URL = `${API_DOMAIN}admins/course/`;

async function getCourses() {
  const get = () => {
    return fetch(BASE_URL, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      }
    });
  }

  return await get();
}

async function createCourse(body) {
  const create = () => {
    return fetch(BASE_URL, {
      method: "POST",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      },
      body: body,
    });
  }

  return await create();
}

async function editCourses(id, body) {
  const edit = () => {
    return fetch(BASE_URL + `${id}/`, {
      method: "PATCH",
      mode: "cors",
      cache: 'default',
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await edit();
}

async function deleteCourse(id) {
  const del = () => {
    return fetch (BASE_URL + `${id}/`, {
      method: "DELETE",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      }
    });
  }

  return await del();
}

export { getCourses, editCourses, createCourse, deleteCourse };