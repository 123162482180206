import AdminToken from "./AdminToken";
import API_DOMAIN from './CONFIG.js';

const BASE_URL = `${API_DOMAIN}admins/`;

async function getModules() {
  const get = () => {
    return fetch (BASE_URL + 'modules/', {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      }
    });
  }

  return await get();
}

async function getModuleResults(id) {
  const get = () => {
    return fetch(BASE_URL + `users/${id}/module-results/`, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      }
    }); 
  }

  return await get();
}

async function createModule(body) {
  const create = () => {
    return fetch(BASE_URL + 'modules/', {
      method: "POST",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    });
  }
  
  return await create();
}

async function editModule(id,body) {
  const update = () => {
    return fetch (BASE_URL + `modules/${id}/`, {
      method: "PATCH",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    });
  }

  return await update();
}

async function deleteModule(id) {
  const del = () => {
    return fetch(BASE_URL + `modules/${id}/`, {
      method: "DELETE",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      },
    });
  }

  return await del();
}

async function getTestResults(id) {
  const get = () => {
    return fetch(BASE_URL + `module_result/${id}/test_result/`, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
    })
  }

  return await get();
}

async function editTestResult(id,body) {
  const edit = () => {
    return fetch(BASE_URL + `module_result/${id}/`, {
      method: "PATCH",
      mode: 'cors',
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return edit();
}

export { getModules, getModuleResults, createModule, deleteModule, editModule, getTestResults, editTestResult };