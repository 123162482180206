import AdminToken from "./AdminToken";
import API_DOMAIN from './CONFIG.js';

const BASE_URL = `${API_DOMAIN}admins/modules/`;

async function getLessons(moduleId) {
  const get = () => {
    return fetch (BASE_URL + `${moduleId}/lessons/list/`, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
    });
  }

  return await get();
}

async function createLesson(body) {
  const create = () => {
    return fetch (BASE_URL + `1/lessons/`, {
      method: "POST",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await create();
}

async function deleteLesson(id) {
  const del = () => {
    return fetch (`${API_DOMAIN}admins/lessons/${id}/`, {
      method: "DELETE",
      mode: 'cors',
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      }
    })
  }

  return await del();
}

async function editLesson(id,body) {
  const edit = () => {
    return fetch (`${API_DOMAIN}admins/lessons/${id}/`, {
      method: "PATCH",
      mode: "cors",
      cache: 'default',
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    });
  }

  return await edit();
}

async function createFile(body) {
  const crtFile = () => {
    return fetch (`${API_DOMAIN}admins/lessons_file/`, {
      method: "POST",
      mode: "cors",
      cache: 'default',
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    });
  }

  return await crtFile();
}

async function deleteFile(id) {
  const del = () => {
    return fetch(`${API_DOMAIN}admins/lessons_file/${id}/`, {
      method: "DELETE",
      mode: 'cors',
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
    });
  }

  return await del();
}

async function createVideo(body) {
  const crtVideo = () => {
    return fetch (`${API_DOMAIN}admins/lessons_video/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await crtVideo();
}

async function deleteVideo(id) {
  const del = () => {
    return fetch(`${API_DOMAIN}admins/lessons_video/${id}/`, {
      method: "DELETE",
      mode: 'cors',
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      }
    })
  }

  return await del();
}

async function editVideo(id,body) {
  const edtVideo = () => {
    return fetch (`${API_DOMAIN}admins/lessons_video/${id}/`, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await edtVideo();
}

export {
  getLessons,
  createLesson,
  deleteLesson,
  editLesson,
  createFile,
  createVideo,
  editVideo,
  deleteFile,
  deleteVideo,
};