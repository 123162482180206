import React from "react";
import './AdminList.scss';
import Pagination from '../Pagination/Pagination.jsx';
import AdminCreateFrom from './AdminCreateForm/AdminCreateForm.jsx';
import AdminDelete from './AdminDelete/AdminDelete.jsx';
import { getAdmins } from '../../API/Admins.js';

const PageSize = 10;

class AdminList extends React.Component {
  state = {
    currentPage: 1,
    formIsVisible: false,
    deletionFormIsVisible: false,
    deletionId: -1,
    adminArr: [],
  }

  componentDidMount() {
    this.downloadAdmins();
  }

  downloadAdmins() {
    let result = getAdmins();
    result.then((data) => {
      if (!data.ok) {
        throw new Error('Не удалось загрузить список');
      }
      return data.json();
    })
    .then((dataJSON) => {
      this.useData(dataJSON);
    }).catch(alert);
  }

  useData(val) {
    this.setState(() => ({adminArr: val}));
  }

  setCurrentPage(value) {
    this.setState({currentPage: value});
  }

  showForm() {
    this.setState((oldState) => {return({formIsVisible: true})})
  }

  hideForm() {
    this.setState(() => {return({formIsVisible: false})})
    this.downloadAdmins();
  }

  showDeletion(value) {
    this.setDeletionId(value);
    this.setState((oldState) => {return({deletionFormIsVisible: true})});
  }

  hideDeletion() {
    this.setState((oldState) => {return({deletionFormIsVisible: false})});
    this.downloadAdmins();
  }

  setDeletionId(value) {
    this.setState({deletionId: value});
  }

  render() {
    const { currentPage, adminArr, deletionId } = this.state;
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = adminArr.slice(firstPageIndex, lastPageIndex);
    const firstNumber = firstPageIndex + 1;
    let lastNumber = lastPageIndex;
    if (lastPageIndex <= adminArr.length) {
      lastNumber = lastPageIndex;
    } else {
      lastNumber = adminArr.length;
    }

    return(
      <div className="admin-list__page">
        <div className="page__header">
          <div className="header__title">
            Администраторы
          </div>
          <button
            type="button"
            className="header__add-button"
            onClick={() => this.showForm()}
          >
            Добавить
          </button>
          {this.state.formIsVisible &&
            <AdminCreateFrom
              show={this.state.formIsVisible}
              handleClose={this.hideForm.bind(this)}
            />
          }
          {this.state.deletionFormIsVisible &&
            <AdminDelete
              show={this.state.deletionFormIsVisible}
              handleClose={this.hideDeletion.bind(this)}
              id={deletionId}
            />
          }
        </div>
        <div className="page__pagination">
          <div className="page__table">
            <div className="grid__table table__head">
              <div className="head__name head">Имя</div>
              <div className="head__phone head">Номер телефона</div>
              <div className="head__email head">E-mail</div>
              <div className="head__access head">Права доступа</div>
              <div className="head__edit head">Ред.</div>
            </div>
            {currentData.map(
              (item)=> {return(
                <div className="grid__table row" key={item.id}>
                  <div className="table__item">{item.user.name}</div>
                  <div className="table__item phone">{item.user.phone_number}</div>
                  <div className="table__item">{item.user.email}</div>
                  <div className="table__item">
                    {item.is_admin ? 'Администратор' : 'Модератор'}
                  </div>
                  <div className="table__item">
                    <div className="delete-button" onClick={()=>this.showDeletion(item.user.id)}></div>
                  </div>
                </div>
              )}
            )}
          </div>
          <div className="pagination__nav">
          <div className='pagination__nav--number'>
              {`${firstNumber}-${lastNumber} из ${adminArr.length}`}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={adminArr.length}
              pageSize={PageSize}
              onPageChange={page => this.setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default AdminList;