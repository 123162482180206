import React from "react";
import './CourseBookingCard.scss';
import { editCourseStatus } from "../../../API/CourseBooking";

class CourseBookingCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: this.props.object,
      status: this.props.object.status,
    }
  }

  editStatus(id, status) {
    const response = editCourseStatus(id, status);
    response.then(data => {
      this.props.update();
    }).catch(alert);
  }

  handleStatusChange(e) {
    this.setState(() => ({status: e.target.value}));
    this.editStatus(this.state.booking.id, e.target.value);
  }

  render() {
    const { object } = this.props;
    return(
      <div className="course-booking__card">
        <div className="grid__table row">
          <div className="table__item info">
            <div
              className="info__photo"
              style={{backgroundImage: `url(${object.student.photo})`}}
            ></div>
            <div className="info__wrapper">
              <div className="info__name">{object.student.user.name}</div>
              <div className="info__email">{object.student.user.email}</div>
            </div>
          </div>
          <div className="table__item phone">{object.student.user.phone_number}</div>
          <div className="table__item course-name">{object.course.title}</div>
          <div className="table__item date">{object.created_at.split('-').reverse().join('.')}</div>
          <div className='table__item status'>
            <select
              name="status"
              id="status"
              className="select-input"
              disabled={object.status === 'Canceled' || object.status === 'Payed'}
              value={object.status}
              onChange={(e) => this.handleStatusChange(e)}
            >
              <option value="New">Новый</option>
              <option value="In progress">В работе</option>
              <option value="Canceled">Отмена</option>
              <option value="Payed">Оплачено</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default CourseBookingCard;