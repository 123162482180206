import React from "react";
import './ScientistCard.scss';
import EditForm from "../EditForm/EditForm.jsx";

class ScientistCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.obj.id,
      name: this.props.obj.name,
      image: this.props.obj.image,
      date: this.props.obj.date,
      short_desc: this.props.obj.short_desc,
      description: this.props.obj.description,
      is_teacher: this.props.obj.description,
      goverment_member: this.props.obj.description,
      academic_council: this.props.obj.description,
    }
  }

  state = {
    formIsVisible: false,
  }

  showForm() {
    this.setState({formIsVisible: true})
  }

  hideForm(isDeleting = false) {
    this.setState({formIsVisible: false});
    if (isDeleting) {
      this.props.update();
    } else {
      this.props.update(this.props.currentPage);
    }
  }


  render() {
    return(
      <div className="scientist__card">
        <div className="grid__table row">
          <div className="table__item number">{this.props.obj.id}</div>
          <div
            className="table__item photo"
            onClick={() => this.props.update(`?page=${this.props.currentPage}`)}
          >
            <div className="table-item__photo" style={{backgroundImage:'url(' + this.props.obj.image + `)`}}>
            </div>
          </div>
          <div className="table__item name">{this.props.obj.name}</div>
          <div className="table__item desc">{
            this.props.obj.short_desc.split(' ').length > 10
            ? this.props.obj.short_desc.split(' ').slice(0,10).join(' ') + '...'
            : this.props.obj.short_desc.split(' ').slice(0,10).join(' ')
          }</div>
          <div className="table__item edit__item">
            <div
              className="edit-button"
              onClick={() => {this.showForm()}}></div>
          </div>
        </div>
        {this.state.formIsVisible &&
            <EditForm
              show={this.state.formIsVisible}
              handleClose={this.hideForm.bind(this)}
              obj={this.props.obj}
            />
        }
      </div>
    )
  }
}

export default ScientistCard;
