import AdminToken from "./AdminToken";
import API_DOMAIN from './CONFIG.js';

const BASE_URL = `${API_DOMAIN}admins/`;

async function getQuestions() {
  const get = () => {
    return fetch(BASE_URL + "questions/", {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      }
    })
  }

  return await get();
}

async function createQuestion(body) {
  const create = () => {
    return fetch (BASE_URL + "questions/", {
      method: "POST",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      },
      body: JSON.stringify(body),
    })
  }

  return await create();
}

async function deleteQuestion(id) {
  const del = () => {
    return fetch (BASE_URL + `questions/${id}/`, {
      method: "DELETE",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
    })
  }

  return await del();
}

async function updateQuestion(id, body) {
  const update = () => {
    return fetch (BASE_URL + `questions/${id}/`, {
      method: "PATCH",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      },
      body: JSON.stringify(body),
    })
  }

  return await update();
}

async function updateChoice(id,body) {
  const update = () => {
    return fetch (BASE_URL + `choices/${id}/`, {
      method: "PATCH",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      },
      body: JSON.stringify(body),
    })
  }

  return await update();
}

export { getQuestions, createQuestion, deleteQuestion, updateQuestion, updateChoice };