import React from "react";
import './OnlineEducation.scss';
import CoursesList from "./CoursesList/CoursesList";
import ModulesList from "./ModulesList/ModulesList";
import ModuleProfile from "./ModuleProfile/ModuleProfile";

class OnlineEducation extends React.Component {
  state = {
    currentPage: 'Courses',
    course: {},
    module: {},
  }

  openCourses() {
    this.setState(() => ({
      currentPage: "Courses",
    }));
  }

  openModules(course) {
    this.setState(() => ({
      course: course,
      currentPage: "Modules",
    }));
  }

  openModuleProfile(module) {
    this.setState(() => ({
      module: module,
      currentPage: "ModulePage",
    }));
  }

  renderChosenPage (page) {
    switch (page) {
      case "Courses":
        return <CoursesList openModules={this.openModules.bind(this)} />;
      case "Modules":
        return <ModulesList
                  openCourses={this.openCourses.bind(this)}
                  openModuleProfile={this.openModuleProfile.bind(this)}
                  course={this.state.course}
              />;
      case "ModulePage":
        return  <ModuleProfile
                  openModules={this.openModules.bind(this)}
                  course={this.state.course}
                  module={this.state.module}
                />;
      default:
        return "Courses";
    }
  }

  render() {
    const { currentPage } = this.state;
    return(
      <>
        {
          this.renderChosenPage(currentPage)
        }
      </>
    );
  }
}

export default OnlineEducation;