import React from "react";
import './CoursesList.scss';
import Pagination from "../../Pagination/Pagination";
import { getCourses, editCourses } from "../../../API/Courses";
import CoursesForm from "./CoursesForm/CoursesForm";
import classNames from "classnames";

const PageSize = 10;
let intervalId;

class CoursesList extends React.Component {
  constructor(props) {
    super(props);

    this.downloadCourses = this.downloadCourses.bind(this);
  }
  state = {
    searchQuery: '',
    currentPage: 1,
    formIsVisible: false,
    coursesArr: [],
    isEditing: false,
    course: {},
    intervalId: '',
  }

  componentDidMount() {
    this.downloadCourses();

    intervalId = setInterval(this.downloadCourses, 2000);
  }

  componentWillUnmount() {
    clearInterval(intervalId);
  }

  downloadCourses() {
    const result = getCourses();
    result.then(data => {
      if (!data.ok) {
        throw new Error('Не удалось загрузить список курсов');
      }
      return data.json();
    }).then(dataJSON => {
      if (dataJSON.length === 0) {
        this.setState(() => ({
          coursesArr: [],
        }));
      } else {
        this.setState(() => ({coursesArr: dataJSON.sort((a,b) => a.id - b.id)}));
      }
    }).catch(alert);
  }

  setCurrentPage(value) {
    this.setState({currentPage: value});
  }

  setSearchQuery(event) {
    this.setState(() => ({
      searchQuery: event.target.value,
      currentPage: 1,
    }));
  }

  showCreationForm() {
    this.setState(() => {return({
      isEditing: false,
      courseObj: {},
      formIsVisible: true
    })})
  }

  showEditForm(courseObj) {
    this.setState(() => ({
      isEditing: true,
      course: courseObj,
      formIsVisible: true,
    }));
  }

  hideForm() {
    this.setState(() => {return({formIsVisible: false})});
    this.downloadCourses();
  }

  searchFilter() {
    return this.state.coursesArr.filter(
      item => item.title.toLowerCase().includes(this.state.searchQuery.toLowerCase())
    );
  }

  changeVisibility(course) {
    if (!course.is_published) {
      alert('Вы не можете изменить видимость курса до публикации');
      return;
    }
    const formData = new FormData();
    formData.append('is_visible',!course.is_visible);
    const result = editCourses(course.id,formData);
    result.then(
      data => {
        if (!data.ok) {
          throw new Error('Не удалось изменить видимость курса');
        }
        this.downloadCourses();
      }
    ).catch(alert);
  }

  publishCourse(course) {
    if (course.is_published) {
      alert('Вы не можете опубликовать курс дважды');
      return;
    }

    const confirmation = window.confirm('Вы уверены что хотите опубликовать курс?');

    if (!confirmation) {
      return;
    }

    const formData = new FormData();
    formData.append('is_published',true);
    const result = editCourses(course.id,formData);
    result.then(
      data => {

        if (data.status === 400) {
          return data.json();
        }

        if (!data.ok) {
          throw new Error('Не удалось опубликовать курс');
        }
        this.downloadCourses();
        return [];
      }
    ).then(
      dataJSON => {
        switch(true) {
          case (dataJSON[0] === 'Add modules before publishing'):
            alert('Необходимо добавить модули перед публикацией.');
            break;
          case (dataJSON[0] === 'Add lessons before publishing'):
            alert('Необходимо добавить уроки перед публикацией.');
            break;
          default:
            break;
        }
      }
    ).catch(alert);
  }

  render() {
    const { currentPage, coursesArr } = this.state;
    const filteredArray = this.searchFilter();
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = filteredArray.slice(firstPageIndex, lastPageIndex).sort((a, b) => a.id - b.id);
    const firstNumber = firstPageIndex + 1;
    let lastNumber = lastPageIndex;
    if (lastPageIndex <= filteredArray.length) {
      lastNumber = lastPageIndex;
    } else {
      lastNumber = filteredArray.length;
    }
    return(
      <div className="courses-list__page">
        <div className='page__header'>
          <div className='info'>
            <h2 className='info__title'>Курсы</h2>
          </div>
          <div className='navigation'>
            <input
              type="search"
              placeholder='Поиск по названию'
              className='navigation__search-bar'
              value={this.state.searchQuery}
              onChange={(event) => this.setSearchQuery(event)}
            />
            <button 
              className='navigation__add-button'
              onClick={() => {this.showCreationForm()}}
            >
              Добавить
            </button>
            {
              this.state.formIsVisible &&
              <CoursesForm 
                show={this.state.formIsVisible}
                handleClose={this.hideForm.bind(this)}
                isEditing={this.state.isEditing}
                course={this.state.course}
              />
            }
          </div>
        </div>
        <div className="page__pagination">
          <div className="page__table">
          <div className="grid__table table__head">
              <div className="head__number head">№</div>
              <div className="head__name head">Название курса</div>
              <div className="head__date head">Дата публикации</div>
              <div className="head__count head">Кол-во модулей</div>
              <div className="head__hide head">Скрыть</div>
              <div className="head__edit head">Ред.</div>
              <div className="head__publication head">Публикация</div>
          </div>
            {currentData.map(
              (item) => {return(
                <div className="grid__table row" key={item.id}>
                  <div className="table__item number">{item.id}</div>
                  <div
                    className="table__item name"
                    onClick={() => this.props.openModules(item)}
                  >
                    {item.title}
                  </div>
                  <div className="table__item date">{item.date.split('-').reverse().join('.')}</div>
                  <div className="table__item count">{item.amount_of_modules}</div>
                  <div className="table__item hide__item">
                    <div
                      className={classNames('hide-button',{'hide-button--hidden': !item.is_visible})}
                      onClick={() => this.changeVisibility(item)}></div>
                  </div>
                  <div className="table__item edit__item">
                    <div
                      className="edit-button"
                      onClick={() => {this.showEditForm(item)}}></div>
                  </div>
                  <div className="table__item publicate-course">
                    <div
                      className={classNames("publication-button",{disabled: item.is_published})}
                      onClick={() => this.publishCourse(item)}
                    >
                      {
                        item.is_published
                        ? "Опубликовано"
                        : "Опубликовать"
                      }
                    </div>
                  </div>
                </div>
              )}
            )}
          </div>
          <div className="pagination__nav">
          <div className='pagination__nav--number'>
              {`${firstNumber}-${lastNumber} из ${coursesArr.length}`}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={filteredArray.length}
              pageSize={PageSize}
              onPageChange={page => this.setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CoursesList;