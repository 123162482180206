import classNames from "classnames";
import React from "react";
import './BookingCard.scss';
import {changeStatus} from "../../../API/Bookings";


class BookingCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: this.props.object,
      status: this.props.object.status + '',
    }
  }

  editStatus(e) {
    const status = e.target.value === 'true' ? true : false;
    const formData = new FormData();
    formData.append('status',status);
    const response = changeStatus(this.state.booking.id, formData);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('Не удалось поменять статус.');
        }
        this.props.update();
      }
    ).catch(alert);
  }

  handleStatusChange(e) {
    this.editStatus(e);
  }

  render() {
    const { booking, status } = this.state;
    const date_from = booking.record.date_from.split('-').reverse().join('.');
    const date_to = booking.record.date_to.split('-').reverse().join('.');
    return(
      <div className="booking__card">
        <div className="grid__table row">
          <div className="table__item info">
            <div
              className="info__photo"
              style={{backgroundImage: `url(${booking.student.photo})`}}
            ></div>
            <div className="info__wrapper">
              <div className="info__name">{booking.student.user.name}</div>
              <div className="info__email">{booking.student.user.email}</div>
            </div>
          </div>
          <div className="table__item phone">{booking.student.user.phone_number}</div>
          <div className="table__item location">{booking.record.training.city}</div>
          <div className="table__item date">{`${date_from} - ${date_to}`}</div>
          <div className={classNames("table__item","status",{'status--booked': status === 'true'})}>
            <select
              name="status"
              id="status"
              className="select-input"
              value={this.props.object.status}
              onChange={(e) => this.handleStatusChange(e)}
            >
              <option value={true}>Забронирован</option>
              <option value={false}>В ожидании</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default BookingCard;