import AdminToken from './AdminToken.js';
import API_DOMAIN from './CONFIG.js';

const BASE_URL = `${API_DOMAIN}admins/admins/`;

async function getAdmins() {
  const fetchAdmins = () => {
    return fetch(BASE_URL, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      },
    });
  }

  const result = await fetchAdmins();
  return result;
}

async function deleteAdmin(id) {
  const delAdmin = () => {
    return fetch (`${API_DOMAIN}admins/delete/${id}/`, {
      method: "DELETE",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      },
    });
  }

  const result = await delAdmin();
  return result;
}

async function createAdmin(body) {
  const crtAdmin = () => {
    return fetch (BASE_URL, {
      method: "POST",
      mode: "cors",
      cache: "default",
      
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
        "Content-Type": 'application/json',
      },
      body: body,
    });
  }
  
  const result = await crtAdmin();
  return result;
}

export { getAdmins, deleteAdmin, createAdmin };