import react from 'react';
import './App.scss';
import ScientistsList from './Components/ScientistsList/ScientistsList';
import AdminList from './Components/AdminList/AdminList';
import DocumentList from './Components/DocumentList/DocumentList';
import PublicationList from './Components/PublicationList/PublicationList';
import Accordion from './Components/Accordeon/Accordion';
import VideoList from './Components/VideoList/VideoList';
import UserList from './Components/UserList/UserList';
import RecordList from './Components/RecordList/RecordList';
import BookingList from './Components/BookingList/BookingList';
import CourseBookingList from './Components/CourseBookingList/CourseBookingList';
import OnlineEducation from './Components/OnlineEducation/OnlineEducation';
import LoginScreen from './Components/LoginScreen/LoginScreen';

import * as logos from './Util/styles/icon-import.jsx';

class App extends react.Component {
  state = {
    chosenPage: 'users',
    isSignedIn: false,
    accordionOpened: false,
  }

  changePage = (event) => {
      if (event.target.value === 'trainings-1' || event.target.value === 'trainings-2') {
        this.setState(() => ({
          chosenPage: event.target.value
        }));
      } else {
        this.setState(() => ({
          accordionOpened: false,
          chosenPage: event.target.value
        }));
      }
  };

  openAccordion() {
    this.setState({accordionOpened: true});
  }

  changeSignInStatus(status) {
    this.setState((state) => ({isSignedIn: !state.isSignedIn}))
  }

  renderSwitch = (page) => {
    switch(page) {
      case 'none':
        return 'No page selected';
      case 'users':
        return <UserList />;
      case 'scientists':
        return <ScientistsList />;
      case 'documents':
        return <DocumentList />;
      case 'videos':
        return <VideoList />;
      case 'publications':
        return <PublicationList />;
      case 'online':
        return <OnlineEducation />;
      case 'trainings-1':
        return <BookingList />;
      case 'trainings-2':
        return <RecordList />;
      case 'admins':
        return <AdminList />;
      case 'booking':
        return <CourseBookingList />;
      default:
        break;
    }
  }


  render() {
    const { chosenPage, isSignedIn,accordionOpened } = this.state;

    return (
      <div className="App">
        {
          isSignedIn
          ? <div className='body'>
          <div className='menu'>
            <div className='menu__logo-wrapper'>
              <img src={logos.if_logo} alt="IF Logo" className='logo'/>
            </div>
            <div className='menu__page-buttons'>
              <button
                className={`page-button ${'users'===chosenPage ? 'active' : ''}`}
                value={'users'}
                onClick={(event) => this.changePage(event)}
              >
                <img
                  src={logos.user_icon}
                  alt="Users icon"
                  className='page-button__icon'
                />
                <div className='page-button__text'>
                  Пользователи
                </div>
              </button>
              <button
                className={`page-button ${'scientists'===chosenPage ? 'active' : ''}`}
                onClick={(event) => this.changePage(event)}
                value={'scientists'}
              >
                <img
                  src={logos.about_us_icon}
                  alt="Users icon"
                  className='page-button__icon'
                />
                <div className='page-button__text'>
                  Научные деятели
                </div>
              </button>
              <button
                className={`page-button ${'documents'===chosenPage ? 'active' : ''}`}
                onClick={(event) => this.changePage(event)}
                value={'documents'}
              >
                <img
                  src={logos.documents_icon}
                  alt="Users icon"
                  className='page-button__icon'
                />
                <div className='page-button__text'>
                  Консенсус документы
                </div>
              </button>
              <button
                className={`page-button ${'videos'===chosenPage ? 'active' : ''}`}
                onClick={(event) => this.changePage(event)}
                value={'videos'}
              >
                <img
                  src={logos.video_icon}
                  alt="Users icon"
                  className='page-button__icon'
                />
                <div className='page-button__text'>
                  Видео-лекции
                </div>
              </button>
              <button
                className={`page-button ${'publications'===chosenPage ? 'active' : ''}`}
                onClick={(event) => this.changePage(event)}
                value={'publications'}
              >
                <img
                  src={logos.publications_icon}
                  alt="Users icon"
                  className='page-button__icon'
                />
                <div className='page-button__text'>
                  Научные публикации
                </div>
              </button>
              <button
                className={`page-button ${'online'===chosenPage ? 'active' : ''}`}
                onClick={(event) => this.changePage(event)}
                value={'online'}
              >
                <img
                  src={logos.online_icon}
                  alt="Users icon"
                  className='page-button__icon'
                />
                <div className='page-button__text'>
                  Онлайн обучение
                </div>
              </button>
              <Accordion
                title={'Практические тренинги'}
                open={this.openAccordion.bind(this)}
                isOpened={this.state.accordionOpened}
              >
                <button
                  className={`page-sub-button ${'trainings-1'===chosenPage ? 'active' : ''}`}
                  onClick={(event) => this.changePage(event)}
                  value={'trainings-1'}
                >
                  <div className='page-sub-button__text'>
                    Бронирование записи
                  </div>
                </button>
                <button
                  className={`page-sub-button ${'trainings-2'===chosenPage ? 'active' : ''}`}
                  onClick={(event) => this.changePage(event)}
                  value={'trainings-2'}
                >
                  <div className='page-sub-button__text'>
                    Создание записи
                  </div>
                </button>
              </Accordion>
              <button
                className={`page-button ${'admins'===chosenPage ? 'active' : ''}`}
                onClick={(event) => this.changePage(event)}
                value={'admins'}
              >
                <img
                  src={logos.admin_icon}
                  alt="Users icon"
                  className='page-button__icon'
                />
                <div className='page-button__text'>
                  Администраторы
                </div>
              </button>
              <button
                className={`page-button ${'booking'===chosenPage ? 'active' : ''}`}
                onClick={(event) => this.changePage(event)}
                value={'booking'}
              >
                <img
                  src={logos.admin_icon}
                  alt="Users icon"
                  className='page-button__icon'
                />
                <div className='page-button__text'>
                  Бронирование
                </div>
              </button>
            </div>
          </div>
          <div className='main'>
            {this.renderSwitch(chosenPage)}
          </div>
        </div>
          : <LoginScreen
              changeSignIn={this.changeSignInStatus.bind(this)}
            />
        }
      </div>
    );
  }
}

export default App;
