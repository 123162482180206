import AdminToken from "./AdminToken";
import API_DOMAIN from './CONFIG.js';

const BASE_URL = `${API_DOMAIN}admins/videos/`;

async function fetchVideos(page = '') {
  const getVideos = () => {
    return fetch (BASE_URL + page, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      },
    });
  }

  const result = await getVideos();
  return result;
}

async function createVideo(body) {
  const crtVideo = () => {
    return fetch(BASE_URL, {
      method: "POST",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      },
      body: body,
    }).then((response) => response);
  }

  const result = await crtVideo();
  return result;
}

async function deleteVideo(id) {
  const delVideo = () => {
    return fetch (BASE_URL + id,{
      method: "DELETE",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      }
    }).then((response) => response);
  }

  const result = await delVideo();
  return result;
}

async function editVideo(body, id) {
  const updateVideo = () => {
    return fetch (BASE_URL + `${id}/`, {
      method: "PATCH",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      },
      body: body,
    });
  }

  const result = await updateVideo();
  return result;
}

export { fetchVideos, createVideo, deleteVideo, editVideo };