import AdminToken from "./AdminToken";
import API_DOMAIN from './CONFIG.js';

const BASE_URL = `${API_DOMAIN}admins/course_booking/`;


async function getCourseBookings(page = '') {
  const get = () => {
    return fetch(BASE_URL + page, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      },
    });
  }

  const result = await get();
  return result;
}

async function editCourseStatus(id, status) {
  const edit = () => {
    return fetch(BASE_URL + id +'/', {
      method: "PATCH",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({
        status: status
      }),
    });
  }

  const result = await edit();
  return result;
}

export {getCourseBookings, editCourseStatus};
