import React from "react";
import './CourseBookingList.scss';
import Pagination from "../Pagination/Pagination";
import classNames from "classnames";
import {getCourseBookings} from "../../API/CourseBooking";
import CourseBookingCard from "./CourseBookingCard/CourseBookingCard";

const PageSize = 10;

class CourseBookingList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: "",
      filterQuery: 'All',
      currentPage: 1,
      bookingsArr: [],
      count: 0,
    }
  }

  componentDidMount() {
    this.downloadCourseBooking();
  }

  downloadCourseBooking(page = this.state.currentPage, search = this.state.searchQuery,status = this.state.filterQuery) {
    const statusQuery = status === 'All' ? '' : status.toLowerCase();
    const response = getCourseBookings(`?page=${page}&q=${search}&status=${statusQuery}`);

    response.then(data => {
      if (!data.ok) {
        throw new Error('Не удалось загрузить список');
      }
      return data.json();
    }).then(dataJSON => {

      if(dataJSON.length === 0) {
        this.setState(() => ({bookingsArr: []}));
      } else {
        this.setState(() => ({bookingsArr: dataJSON.results}));
      }
    })
    .catch(alert);
  }

  setCurrentPage(value) {
    this.setState({currentPage: value});
    this.downloadCourseBooking(value,this.state.searchQuery);
  }

  setFilterQuery(value) {
    this.setState(() => ({filterQuery: value}));
    if (value === 'All') {
      this.downloadCourseBooking(1,this.state.searchQuery,'All');
    } else {
      this.downloadCourseBooking(1,this.state.searchQuery,value);
    }
  }

  setSearchQuery(e) {
    this.setState(() => ({
      searchQuery: e.target.value
    }));
    this.downloadCourseBooking(1,e.target.value);
  }

  render() {
    const { currentPage,searchQuery, bookingsArr } = this.state;
    const filteredArr = bookingsArr;
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = filteredArr.slice(firstPageIndex, lastPageIndex);
    const firstNumber = firstPageIndex + 1;
    let lastNumber = lastPageIndex;
    if (lastPageIndex <= filteredArr.length) {
      lastNumber = lastPageIndex;
    } else {
      lastNumber = filteredArr.length;
    }
    return (
      <div className="course-booking__page">
        <div className='page__header'>
          <div className='info'>
            <h2 className='info__title'>Бронирование курсов</h2>
          </div>
          <div className='navigation'>
            <input
              type="search"
              placeholder='Поиск по номеру телефона или имени'
              className='navigation__search-bar'
              value={searchQuery}
              onChange={(event) => this.setSearchQuery(event)}
            />
            <div className="navigation__filters">
              <div
                className={classNames("filter-button", {'active': this.state.filterQuery === "All"})}
                onClick={() => this.setFilterQuery('All')}
              >
                Все
              </div>
              <div
                className={classNames("filter-button", {'active': this.state.filterQuery === "New"})}
                onClick={() => this.setFilterQuery('New')}
              >
                Новые
              </div>
              <div
                className={classNames("filter-button", {'active': this.state.filterQuery === "Canceled"})}
                onClick={() => this.setFilterQuery('Canceled')}
              >
                Отмена
              </div>
              <div
                className={classNames("filter-button", {'active': this.state.filterQuery === "In progress"})}
                onClick={() => this.setFilterQuery('In progress')}
              >
                В работе
              </div>
              <div
                className={classNames("filter-button", {'active': this.state.filterQuery === "Payed"})}
                onClick={() => this.setFilterQuery('Payed')}
              >
                Оплачено
              </div>
            </div>
          </div>
        </div>
        <div className="page__pagination">
          <div className="page__table">
            <div className="grid__table table__head">
              <div className="head__name head">Имя / E-mail</div>
              <div className="head__phone head">Телефон</div>
              <div className="head__course-name head">Название курса</div>
              <div className="head__date head">Дата запроса</div>
              <div className="head__status head">Статус брони</div>
            </div>
            <div className="table__content">
              {
                currentData.map((item) => {
                  return(
                    <CourseBookingCard
                      object={item}
                      key={item.index}
                      update={this.downloadCourseBooking.bind(this)}
                    />
                  );
                })
              }
            </div>
          </div>
          <div className='pagination__nav'>
            <div className='pagination__nav--number'>
              {`${firstNumber}-${lastNumber} из ${filteredArr.length}`}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={filteredArr.length}
              pageSize={PageSize}
              onPageChange={page => this.setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default CourseBookingList;