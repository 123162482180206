import classNames from "classnames";
import React from "react";
import './TestResultList.scss';

export class TestResultList extends React.Component {

  componentDidMount() {
  }

  render() {
    const { handleClose, testResult } = this.props;
    const currentData = testResult.reverse();
    return(
      <div className="blur__background">
        <div className="result-list__menu">
          <div className="menu__header">
            <div className="menu__header--name">
              Результаты теста
            </div>
            <div
              className="menu__header--close-button"
              onClick={() => handleClose()}
            />
          </div>
          <div className="result__list">
            {
              currentData.map((question,qIndex) => {
                return(
                  <div className="result__question">
                    <div className="result__question--text">
                      {question.user_choice.question.question_text}
                    </div>
                    <ul className="result__question--wrapper">
                      {
                        question.user_choice.question.choices.map((choice,cIndex) => {
                          return(
                            <div className={classNames(
                              "choice-text",
                              {is_right: (question.user_choice.id === choice.id && choice.is_true) || choice.is_true},
                              {is_wrong: ((question.user_choice.id === choice.id && !choice.is_true))}
                            )}>
                              {choice.choice_text}
                            </div>
                          )
                        })
                      }
                    </ul>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

export default TestResultList;