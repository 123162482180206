import React from "react";
import './ModuleForm.scss';
import { getTeachers } from '../../../../API/Scientists/ScientistList';
import { createModule, deleteModule, editModule } from '../../../../API/Modules';


class ModuleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: '',
      course: this.props.course.id,
      teacherId: '',
      amount_of_lessons: '',
      module_number: '',
      teachersArr: [],
    }
  }

  componentDidMount() {
    const response = getTeachers();
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('Не удалось загрузить список преподавателей');
        }
        return data.json();
      }
    ).then(
      dataJSON => {
        this.setState(() => ({
          teachersArr: dataJSON,
          teacherId: dataJSON[0].id,
        }));
      }
    ).catch(alert);

    if (this.props.isEditing) {
      this.setData();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const {title, description, teacherId, amount_of_lessons, module_number} = this.state;

    if (this.props.course.is_published) {
      alert('Нельзя изменять опубликованый курс.');
      return;
    }

    if (amount_of_lessons < this.props.module.amount_of_lessons) {
      alert('Нельзя снизить количество уроков.');
      return;
    }

    const formData = new FormData();
    formData.append('course',this.props.course.id);
    formData.append('teacher',teacherId);
    formData.append('description',description);
    formData.append('title',title);
    formData.append('amount_of_lessons',amount_of_lessons);
    formData.append('module_number',module_number);

    if (this.props.isEditing) {
      formData.delete('course');

      const response = editModule(this.props.module.id, formData);
      response.then(
        data => {

          if (data.status === 403) {
            throw new Error('Менеджерам нельзя изменять модули .');
          }

          if (data.status === 400) {
            return data.json();
          }

          if (!data.ok) {
            throw new Error('Не удалось обновить модуль');
          }
          this.props.handleClose();
          return {};
        }
      ).then(
        dataJSON => {
          switch(true) {
            case ('module_number' in dataJSON):
              alert('Модуль с таким номером уже создан.');
              break;
            default:
              break;
          }
        }
      ).catch(alert);
    } else {
      const response = createModule(formData);
      response.then(
        data => {

          if (data.status === 403) {
            throw new Error('Менеджерам нельзя создавать новые модули.');
          }    

          if (data.status === 400) {
            return data.json();
          }

          if (!data.ok) {
            throw new Error('Не удалось создать модуль');
          }
          this.props.handleClose();
          return {};
        }
      ).then(
        dataJSON => {
          switch(true) {
            case ('module_number' in dataJSON):
              alert('Модуль с таким номером уже создан.');
            case ('title' in dataJSON):
              alert('Модуль с таким названием уже создан.');
              break;
            default:
              break;
          }
        }
      ).catch(alert);
    }
  }

  setData() {
    const { module } = this.props;
    this.setState(() => ({
      title: module.title,
      description: module.description,
      amount_of_lessons: module.amount_of_lessons,
      module_number: module.module_number,
      teacherId: module.teacher,
    }));
  }

  handleDelete() {
    if (this.props.course.is_published) {
      alert('Нельзя изменять опубликованый курс');
      return;
    }

    const response = deleteModule(this.props.module.id);
    response.then(
      data => {

        if (data.status === 403) {
          throw new Error('Менеджерам нельзя удалять модули.');
        }  
        
        if (!data.ok) {
          throw new Error('Не удалось удалить модуль');
        }
        this.props.handleClose();
      }
    ).catch(alert);
  }


  render() {
    const {
      title,
      description,
      amount_of_lessons,
      module_number,
      teacherId,
      teachersArr
    } = this.state;
    return (
      <div className="blur__background">
      <div className="module-form__menu">
        <div className="menu__header">
          <div className="menu__header--name">
            {
              this.props.isEditing 
              ? "Редактирование модуля"
              : "Добавьте модуль"
            }
          </div>
          <div
            className="menu__header--close-button"
            onClick={() => this.props.handleClose()}
          />
        </div>
        <form className="menu__form" onSubmit={(e) => this.handleSubmit(e)}>
        <div className="menu__form--main">
            <div className="form__info">
              <div className="form__info--course-name">
                <label htmlFor="course-name" className="label">
                  Название модуля
                </label>
                <input
                  type="text"
                  name="course-name"
                  id="course-name"
                  className="course-name text-input"
                  placeholder="Напишите название модуля"
                  required={!this.props.isEditing}
                  disabled={this.props.course.is_published}
                  autoComplete="off"
                  value={title}
                  onChange={(event) => this.setState({title: event.target.value})}
                />
              </div>
            </div>
          </div>
          <div className="menu__form--additional-info">
            <div className="additional-info__wrapper">
                <div className="input-wrapper">
                  <label htmlFor="module-number" className="label">
                    № модуля
                  </label>
                  <input
                    type="number"
                    name="module-number"
                    id="module-number"
                    className="module-number text-input"
                    placeholder="Напишите число"
                    disabled={this.props.course.is_published}
                    required
                    autoComplete="off"
                    value={module_number}
                    onChange={(event) => this.setState({module_number: event.target.value})}
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="count-number" className="label">
                    Кол-во уроков
                  </label>
                  <input
                    type="number"
                    name="count-number"
                    id="count-number"
                    className="count-number text-input"
                    placeholder="Напишите число"
                    disabled={this.props.course.is_published}
                    required
                    autoComplete="off"
                    value={amount_of_lessons}
                    onChange={(event) => this.setState({amount_of_lessons: event.target.value})}
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="teacher-select" className="label">
                    Выберите преподователя модуля
                  </label>
                  <select
                    name="teacher-select"
                    id="teacher-select"
                    className="teacher-select text-input"
                    disabled={this.props.course.is_published}
                    value={teacherId}
                    onChange={(e) => this.setState({teacherId: e.target.value})}
                  >
                    {
                      teachersArr.map(item => {return(
                        <option value={item.id}>{item.name}</option>
                      )})
                    }
                  </select>
                </div>
            </div>
          </div>
          <div className="full-desc__wrapper">
              <div className="full-desc__header">
                Описание модуля
              </div>
              <textarea
                name="full-desc"
                id="full-desc"
                className="full-desc text-input"
                placeholder="Напишите краткое описание"
                disabled={this.props.course.is_published}
                required
                autoComplete="off"
                value={description}
                onChange={(event) => this.setState({description: event.target.value})}
              >
              </textarea>
            </div>
          <div className="menu__form--control">
            {
              this.props.isEditing
              ? <button
                  type="button"
                  className="button delete-button"
                  onClick={() => this.handleDelete()}
                >Удалить</button>
              : <button
                type="reset"
                className="button cancel-button"
                onClick={() => this.props.handleClose()}
              > Отменить </button>
            }
            <button type="submit" className="button submit-button">
              Сохранить
            </button>
          </div>
        </form>
      </div>
      </div>
    );
  }
}

export default ModuleForm;