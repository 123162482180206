import React from "react";
import './VideoList.scss';
import Pagination from "../Pagination/Pagination";
import VideoCard from "./VideoCard/VideoCard";
import VideoForm from "./VideoForm/VideoForm";
import { fetchVideos } from "../../API/Videos";

const PageSize = 10;

async function getVideos(page = '') {
  const result = await fetchVideos(page);
  return result;
}

class VideoList extends React.Component {
  state = {
    searchQuery: '',
    formIsVisible: false,
    videosArr: [],
    currentPage: 1,
    count: 0,
  }

  componentDidMount() {
    this.donwloadVideos();
  }

  donwloadVideos(page = 1, search = '') {
    let result = getVideos(`?page=${page}&title=${search}`);
    result.then((data) => {
      if (!data.ok) {
        throw new Error('Не удалось загрузить список');
      }
      return data.json();
    }).then((dataJSON) => {
      this.useResult(dataJSON);
    }).catch(alert);
  }

  useResult(val) {
    if (val.length === 0) {
      this.setState({
        videosArr: [],
        count: 0,
      });
    } else {
      this.setState(() => ({
        videosArr: val.results,
        count: val.count,
      }));
    }
  }

  setCurrentPage(value) {
    this.setState({currentPage: value});
    this.donwloadVideos(value);
  }

  setSearchQuery(event) {
    this.setState(() => ({searchQuery: event.target.value}));
    this.donwloadVideos(1,event.target.value);
  }

  showForm() {
    this.setState(() => {return({formIsVisible: true})})
  }

  hideForm() {
    this.setState(() => {return({formIsVisible: false})});
    this.donwloadVideos(this.state.currentPage);
  }

  render() {
    const { currentPage, count, videosArr } = this.state;
    const filteredArray = videosArr;
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = filteredArray;
    const firstNumber = firstPageIndex + 1;
    let lastNumber = lastPageIndex;
    if (lastPageIndex <= count) {
      lastNumber = lastPageIndex;
    } else {
      lastNumber = filteredArray.length;
    }
    return (
      <div className="video-list__page">
        <div className='page__header'>
          <div className='info'>
            <h2 className='info__title'>Видео лекции</h2>
          </div>
          <div className='navigation'>
            <input
              type="search"
              placeholder='Поиск по названию'
              className='navigation__search-bar'
              value={this.state.searchQuery}
              onChange={(event) => this.setSearchQuery(event)}
            />
            <button 
              className='navigation__add-button'
              onClick={() => {this.showForm()}}
            >
              Добавить
            </button>
            {this.state.formIsVisible &&
              <VideoForm
                show={this.state.formIsVisible}
                handleClose={this.hideForm.bind(this)}
                object={{}}
                isEditing={false}
              />
            }
          </div>
        </div>
        <div className="page__pagination">
          <div className="page__table">
            <div className="grid__table table__head">
                <div className="head__number head">№</div>
                <div className="head__photo head">Обложка</div>
                <div className="head__name head">Название</div>
                <div className="head__desc head">Описание</div>
                <div className="head__edit head">Ред.</div>
            </div>
            {
              currentData.map((item) => {
                return(
                  <VideoCard
                    object={item}
                    update={this.donwloadVideos.bind(this)}
                    page={this.state.currentPage}
                    key={item.id  }
                  />
                );
              })
            }
          </div>
          <div className="pagination__nav">
          <div className='pagination__nav--number'>
              {`${firstNumber}-${lastNumber} из ${count}`}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={count}
              pageSize={PageSize}
              onPageChange={page => this.setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default VideoList;