import React, { useState } from "react";
import './EditForm.scss';
import { deleteScientist,editScientist } from "../../../API/Scientists/ScientistList";
import classNames from "classnames";

const EditForm = ({handleClose, show, obj}) => {
  const showHideClassName = show ? "edit-form__menu display-block" : "edit-form__menu display-none";
  const [id] = useState(obj.id);
  const [name] = useState(obj.name);
  const [shortDesc, setShortDesc] = useState(obj.short_desc);
  const [isTeacher, setIsTeacher] = useState(obj.is_teacher);
  const [isGovernmentMember, setIsGovernmentMember] = useState(obj.goverment_member);
  const [isAcademicCouncil, setIsAcademicCouncil] = useState(obj.academic_council);
  const [fullDesc, setFullDesc] = useState(obj.description);
  const [fileObj,setFileObj] = useState({file: ''});
  const [photoPreview, setPhotoPreview] = useState('');
  const formData = new FormData();
  let fileField;
  
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isTeacher && !isAcademicCouncil && !isGovernmentMember) {
      alert('Не выбрано ни одной категории.');
      return;
    }

    formData.append('name',name);
    formData.append('image',fileObj.file);
    formData.append('short_desc',shortDesc);
    formData.append('description',fullDesc);
    formData.append('is_teacher',isTeacher);
    formData.append('goverment_member',isGovernmentMember);
    formData.append('academic_council',isAcademicCouncil);

    if (typeof formData.get('image') === 'string') {
      formData.delete('image');
    }

    let result = editScientist(formData, id);
      result.then (
        (data) => {
          if (!data.ok) {
            throw new Error('Не удалось отредактировать научного деятеля');
          }
          handleClose();
          return {};
      }
    ).then(
      dataJSON => {
      }
    ).catch(alert);
  }

  const handleDelete = () => {
    let result = deleteScientist(id);
    result.then(
      (data) => {
        if (!data.ok) {
          throw new Error('Не удалось удалить научного деятеля');
        }
        handleClose(true);
    }).catch(alert);
  }

  const handleFileUpload = (event) => {
    const imgExtensions = 'jpeg.jpg.gif.tif.png.raw';
    const imageName = event.target.files[0].name;
    const imageExtension = imageName.split('.').pop().toLowerCase();
    if (!imgExtensions.includes(imageExtension.toLowerCase())) {
      alert('Прикрепите картинку');
      return;
    }
    fileField = event.target.files[0];
    setPhotoPreview(URL.createObjectURL(event.target.files[0]));
    setFileObj({
      file: fileField,
    });
  }

  return(
    <div className="blur__background">
      <div className={showHideClassName}>
        <div className="menu__header">
          <div className="menu__header--name">
            Редактирование научного деятеля
          </div>
          <div
            className="menu__header--close-button"
            onClick={() => handleClose()}
          />
        </div>
        <form className="menu__form" onSubmit={(e) => handleSubmit(e)}>
        <div className="menu__form--main">
            <div className="form__image">
              <div className="label">
                Фото деятеля
              </div>
              <label
                htmlFor="image-upload"
                className="image-upload"
                style={{backgroundImage: !!photoPreview ? `url(${photoPreview})` : 'none'}}
                
              >
                <div className={classNames('plus-icon',{hidden: !!photoPreview})}></div>
              </label>
              <input
                type="file"
                name="scientist__image"
                id="image-upload"
                className="image-upload"
                onChange={(e) => handleFileUpload(e)}
              />
            </div>
            <div className="form__info">
              <div className="form__info--short-desc">
                <label htmlFor="short-desc" className="label">
                  Краткое описание
                </label>
                <input
                  type="text"
                  name="short-desc"
                  id="short-desc"
                  className="short-desc text-input"
                  placeholder="Стаж, деятельность, ученое звание"
                  required
                  autoComplete="off"
                  value={shortDesc}
                  onChange={(event) => setShortDesc(event.target.value)}
                />
              </div>
              <div className="form__info--categories">
                <div className="categories__header">
                  Выберите категорию для научного деятеля
                </div>
                <div className="categories__wrapper">
                  <input
                    type="checkbox"
                    name="government-checkbox"
                    id="government-checkbox"
                    className="checkbox"
                    checked={isGovernmentMember}
                    onChange={(event) => setIsGovernmentMember(event.target.checked)}
                  />
                  <div className="label">
                    Члены правления
                  </div>
                  <input
                    type="checkbox"
                    name="teacher-checkbox"
                    id="teacher-checkbox"
                    className="checkbox"
                    checked={isTeacher}
                    onChange={(event) => setIsTeacher(event.target.checked)}
                  />
                  <div className="label">
                    Преподователи
                  </div>
                  <input
                    type="checkbox"
                    name="council-checkbox"
                    id="council-checkbox"
                    className="checkbox"
                    checked={isAcademicCouncil}
                    onChange={(event) => setIsAcademicCouncil(event.target.checked)}

                  />
                  <div className="label">
                    Ученый совет
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="menu__form--full-desc">
            <div className="full-desc__header">
              Полное описание деятельности 
            </div>
            <textarea
              name="full-desc"
              id="full-desc"
              className="full-desc text-input"
              placeholder="Напишите краткое, которое характеризует научного деятеля"
              value={fullDesc}
              required
              autoComplete="off"
              onChange={(event) => setFullDesc(event.target.value)}
            >
            </textarea>
          </div>
          <div className="menu__form--control">
            <button
              type="reset"
              className="button delete-button"
              onClick={() => handleDelete()}
            >
              Удалить
            </button>
            <button
              type="submit"
              className="button submit-button"
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditForm;
