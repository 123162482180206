import AdminToken from "./AdminToken";
import API_DOMAIN from './CONFIG.js';

const BASE_URL = `${API_DOMAIN}admins/publications/`;

async function fetchPublications(page = '') {
  const getPublications = () => {
    return fetch(BASE_URL + page, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      },
    });
  }

  const values = await getPublications();
  return values;
}

async function createPublication(body) {
  const crtPublication = () => {
    return fetch(BASE_URL, {
      method: "POST",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      },
      body: body,
    }).then((response) => response);
  }

  const result = await crtPublication();
  return result;
}

async function deletePublication(id) {
  const delPublication = () => {
    return fetch (BASE_URL + `${id}/`, {
      method: "DELETE",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      },
    }).then((response) => response);
  }

  const result = await delPublication();
  return result;
}

async function editPublication(body, id) {
  const updatePublication = () => {
    return fetch (BASE_URL + `${id}/`, {
      method: "PATCH",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  const result = await updatePublication();
  return result;  
}

export {fetchPublications, createPublication, deletePublication, editPublication};