import React from "react";
import './RecordList.scss';
import Pagination from '../Pagination/Pagination.jsx';
import RecordCard from "./RecordCard/RecordCard";
import RecordForm from "./RecordForm/RecordForm";
import { getTrainings } from "../../API/Trainings";

const PageSize = 20;


class RecordList extends React.Component {
  state = {
    formIsVisible: false,
    recordsArr: [],
    currentPage: 1,
  }

  componentDidMount() {
    this.downloadRecords();
  }

  downloadRecords() {
    let result = getTrainings();
    result.then((data) => {
      if (!data.ok) {
        throw new Error('Не удалось загрузить список');
      }
      return data.json();
    })
    .then((dataJSON) => {
      this.useResult(dataJSON);
    }).catch(alert);
  }

  useResult(val) {
    this.setState(() => ({recordsArr: val}));
  }

  showForm() {
    this.setState(() => {return({formIsVisible: true})})
  }

  hideForm() {
    this.setState(() => {return({formIsVisible: false})});
    this.downloadRecords();
  }

  setCurrentPage(value) {
    this.setState({currentPage: value});
  }

  update() {
    this.forceUpdate()
  }

  render() {
    const { recordsArr, currentPage } = this.state;
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = recordsArr.slice(firstPageIndex, lastPageIndex).sort((a, b) => a.id - b.id);
    const firstNumber = firstPageIndex + 1;
    let lastNumber = lastPageIndex;
    if (lastPageIndex <= recordsArr.length) {
      lastNumber = lastPageIndex;
    } else {
      lastNumber = recordsArr.length;
    }

    return(
      <div className="record-list__page">
        <div className='page__header'>
          <div className='info'>
            <h2 className='info__title'>Создание записи</h2>
            <div className="info__breadcrumbs">
              <span className="info__start-point">Практические тренинги </span>
              <span className="info__end-point">/ Бронирование записи</span>
            </div>
          </div>
          <div className='navigation'>
            <button 
              className='navigation__add-button'
              onClick={() => this.showForm()}
            >
              Создать запись
            </button>
            {this.state.formIsVisible &&
              <RecordForm
                show={this.state.formIsVisible}
                handleClose={this.hideForm.bind(this)}
                object={{}}
                isEditing={false}
              />
            }
          </div>
        </div>
        <div className="page__pagination">
          <div className="pagination__list">
            {
              currentData.map((item) => {
                return (
                  <RecordCard
                    record={item}
                    key={item.id}
                    update={this.downloadRecords.bind(this)}
                  />
                );
              })
            }
          </div>
          <div className='pagination__nav'>
            <div className='pagination__nav--number'>
              {`${firstNumber}-${lastNumber} из ${recordsArr.length}`}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={recordsArr.length}
              pageSize={PageSize}
              onPageChange={page => this.setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RecordList;