import React from "react";
import './AdminDelete.scss';
import { deleteAdmin } from "../../../API/Admins";

const AdminDelete = ({handleClose, show, id}) => {
  const showHideClassName = show ? "admin-delete__menu display-block" : "admin-delete__menu display-none";

  const handleSubmit = (event) => {
    event.preventDefault();
    const response = deleteAdmin(id);
    response.then(data => {

      if (data.status === 403) {
        throw new Error('Менеджерам нельзя удалять администраторов.');
      }

      if (!data.ok) {
        throw new Error('Не удалось удалить администратора');
      }
      handleClose();
    }).catch(alert);
  }

  return(
    <div className="blur__background">
      <div className={showHideClassName}>
        <div className="menu__header">
          <div className="menu__header--name">
            Вы уверены, что хотите удалить администратора?
          </div>
          <div
            className="menu__header--close-button"
            onClick={() => handleClose()}
          />
        </div>
        <form action="POST" className="menu__form" onSubmit={(e) => handleSubmit(e)}>
          <div className="menu__form--control">
            <button
              type="button"
              className="button cancel-button"
              onClick={() => handleClose()}
            >
              Нет
            </button>
            <button type="submit" className="button submit-button">
              Да, удалить
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}


export default AdminDelete;