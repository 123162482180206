import about_us_icon from '../img/about_us-icon.svg';
import documents_icon from '../img/documents-icon.svg';
import user_icon from '../img/user_icon.svg';
import video_icon from '../img/videos-icon.svg';
import publications_icon from '../img/publications-icon.svg';
import online_icon from '../img/online-icon.svg';
import training_icon from '../img/trainings-icon.svg';
import admin_icon from '../img/admins-icon.svg';
import if_logo from '../img/IF_Logo.svg'

export {documents_icon, user_icon, about_us_icon, video_icon, publications_icon,online_icon,training_icon,admin_icon,if_logo};