import React from "react";

import './Accordion.scss';

const Accordion = ({ title, children, open, isOpened }) => {
  const [isOpen, setOpen] = React.useState(isOpened);

  return (
    <div className="accordion-wrapper">
      
      <div
        className={`accordion-title ${isOpened ? "open" : ""}`}
        onClick={() => {setOpen(!isOpened); open()}}
        >
        {title}
      </div>
      <div className={`accordion-item ${!isOpened ? "collapsed" : ""}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;