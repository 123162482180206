import * as React from "react";
import './PublicationForm.scss';
import { createPublication, deletePublication, editPublication } from '../../../API/Publications';
import classNames from "classnames";


const PublicationForm = ({handleClose, object, isEditing}) => {
  const showHideClassName = "publication-form__menu display-block";
  const [title,setTitle] = React.useState('');
  const [number,setNumber] = React.useState('');
  const [description,setDescription] = React.useState('');
  const [date,setDate] = React.useState('');
  const [fileObj,setFileObj] = React.useState({});
  const [photoObj,setPhotoObj] = React.useState({});
  const [photoPreview,setPhotoPreview] = React.useState('');
  const [filePath, setFilePath] = React.useState('');
  const [formDataObj] = React.useState({formData: new FormData()});
  const plusIcon = '../../../Util/img/Plus.svg';

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isEditing  && !('file' in fileObj)) {
      alert('Прикрепите пдф-файл.');
      return;
    }

    if(!isEditing && !('photo' in photoObj)) {
      alert('Прикрепите фото публикации.');
      return;
    }

    const {formData} = formDataObj;
    formData.append('title',title);
    formData.append('description',description);
    formData.append('number',number);
    formData.append('date',date);

    formData.append('photo',photoObj.photo);
    formData.append('files',fileObj.file);

    if (typeof formData.get('photo') === 'string') {
      formData.delete('photo');
    }

    if (typeof formData.get('files') === 'string') {
      formData.delete('files');
    }
    if (isEditing) {
      const response = editPublication(formData,object.id);
      response.then(
        (data) => {

          if (data.status === 403) {
            throw new Error('Менеджерам нельзя редактировать публикации.');
          }

          if (data.status === 400) {
            return data.json();
          }

          if (!data.ok) {
            throw new Error('Не удалость отредактировать запись');
          }
          handleClose();
          return {};
        }
      ).then(
        dataJSON => {
          switch(true) {
            case ('number' in dataJSON):
              alert('Публикация с таким номером уже создана.');
              break;
            default:
              break;
          }
        }
      ).catch(alert);
    } else {
      const response = createPublication(formData);
      response.then(
        (data) => {

          if (data.status === 403) {
            throw new Error('Менеджерам нельзя создавать новые публикации.');
          }

          if (data.status === 400) {
            return data.json();
          }

          if (!data.ok) {
            throw new Error('Не удалость создать запись');
          }
          handleClose();
          return {};
        }
      ).then(
        dataJSON => {
          switch(true) {
            case ('number' in dataJSON):
              alert('Публикация с таким номером уже создана.');
              break;
            default:
              break;
          }
        }
      ).catch(alert);
    }
  }

  const handleDelete = () => {
    const result = deletePublication(object.id);
    result.then((data) => {

      if (data.status === 403) {
        throw new Error('Менеджерам нельзя удалять публикации.');
      } 

      if (!data.ok) {
        throw new Error('Не удалость создать запись');
      }
      handleClose(true);
    }).catch(alert);;
  }

  React.useEffect(() => {
    setData();
  },[isEditing]);

  const deleteFile = () => {
    setFileObj({});
    setFilePath('');
  }

  const setData = () => {
    setTitle(object.title);
    setDescription(object.description);
    setNumber(object.number);
    setDate(object.date);
  }

  const handlePhotoUpload = (event) => {
    const imgExtensions = 'jpeg.jpg.gif.tif.png.raw';
    const imageName = event.target.files[0].name;
    const imageExtension = imageName.split('.').pop();
    if (!imgExtensions.includes(imageExtension.toLowerCase())) {
      alert('Прикрепите картинку');
      return;
    }
    setPhotoObj({photo: event.target.files[0]});
    setPhotoPreview(URL.createObjectURL(event.target.files[0]));
  }

  const handleFileUpload = (event) => {
    if (event.target.files[0]) {
      const fileName = event.target.files[0].name;
      const fileExtension = fileName.split('.').pop().toLowerCase();
      if (fileExtension !== 'pdf') {
        alert('Прикрепите файл формата PDF');
        return;
      }
    }
    setFileObj({file: event.target.files[0]});
  }

  return(
    <div className="blur__background">
      <div className={showHideClassName}>
        <div className="menu__header">
          <div className="menu__header--name">
            
            {isEditing 
            ? "Редактирование научной публикации"
            : "Добавьте научную публикацию"
            }
          </div>
          <div
            className="menu__header--close-button"
            onClick={() => handleClose()}
          />
        </div>
        <form className="menu__form" onSubmit={(e) => handleSubmit(e)}>
        <div className="menu__form--main">
            <div className="form__image">
              <div className="label">
                Фото публикации
              </div>
              <label
                htmlFor="image-upload"
                className="image-upload"
                style={{backgroundImage: !!photoPreview ? `url(${photoPreview})` : `url(${plusIcon})`}}

              >
                <div className={classNames('plus-icon',{hidden: !!photoPreview})}></div>
              </label>
              <input
                type="file"
                name="scientist__image"
                id="image-upload"
                className="image-upload"
                onChange={(event) => handlePhotoUpload(event)}
              />
            </div>
            <div className="form__info">
              <div className="form__info--file-upload">
                <div className="label">
                  Прикрепите пдф-файл
                </div>
                <div className="file-upload__wrapper">
                  <label htmlFor="file-upload" className={classNames("file-upload__label",{uploaded: fileObj.file})}>
                    {
                      fileObj.file
                      ? <div className="file-upload__info">
                          <div className="info__wrapper">
                            <div className="info-name">
                              {fileObj.file.name}
                            </div>
                            <div className="info-size">
                              {Math.round(fileObj.file.size / 1024) + 'KB'}
                            </div>
                          </div>
                        </div>
                      : <div></div>
                    }
                  </label>
                  <div
                    className="file-upload__delete-button"
                    onClick={() => deleteFile()}
                    style={{display: fileObj.file ? 'block' : 'none'}}
                  >
                  </div>
                  <input
                    type="file"
                    name="file-upload"
                    id="file-upload"
                    className="file-upload"
                    value={filePath}
                    onChange={(event) => {handleFileUpload(event);setFilePath(event.target.value)}}
                  />
                </div>
              </div>
              <div className="form__info--publication-name">
                <label htmlFor="publication-name" className="label">
                  Название публикации
                </label>
                <input
                  type="text"
                  name="publication-name"
                  id="publication-name"
                  className="publication-name text-input"
                  placeholder="Напишите название лекции"
                  required={!isEditing}
                  autoComplete="off"
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="menu__form--additional-info">
            <div className="full-desc__wrapper">
              <div className="full-desc__header">
                Описание публикации 
              </div>
              <textarea
                name="full-desc"
                id="full-desc"
                className="full-desc text-input"
                placeholder="Напишите краткое описание"
                required={!isEditing}
                autoComplete="off"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              >
              </textarea>
            </div>
            <div className="additional-info__wrapper">
              <label htmlFor="date-picker" className="label">
                Дата публикации
              </label>
              <input
                type="date"
                name="date-picker"
                id="date-picker"
                className="date-picker text-input"
                required={!isEditing}
                autoComplete="off"
                value={date}
                onChange={(event) => setDate(event.target.value)}
              />
              <label htmlFor="index-number" className="label">
                Порядковый номер
              </label>
              <input
                type="number"
                name="index-number"
                id="index-number"
                className="index-number text-input"
                placeholder="№ публикации"
                required={!isEditing}
                autoComplete="off"
                value={number}
                onChange={(event) => setNumber(event.target.value)}
              />
            </div>
          </div>
          <div className="menu__form--control">
          {
              isEditing
              ? <button
                  type="button"
                  className="button delete-button"
                  onClick={() => handleDelete()}
                >Удалить</button>
              : <button
                type="reset" 
                className="button cancel-button"
                onClick={() => handleClose()}
              > Отменить </button>
            }
            <button type="submit" className="button submit-button">
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}


export default PublicationForm;