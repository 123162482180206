import AdminToken from "./AdminToken";
import API_DOMAIN from './CONFIG.js';

const BASE_URL = `${API_DOMAIN}admins/booking/`;

async function getBookings() {
  const get = () => {
    return fetch(BASE_URL,{
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      }
    });
  }

  const result = await get();
  return result;
}

async function changeStatus(id, body) {
  const change = () => {
    return fetch(BASE_URL + `${id}/`, {
      method: "PATCH",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await change();
}

export {getBookings, changeStatus};