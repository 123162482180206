import AdminToken from '../AdminToken.js';
import API_DOMAIN from '../CONFIG.js';

const BASE_URL = `${API_DOMAIN}admins/scientists/`;

async function fetchScientist(page = '') {
  const getScientists = () => { 
    return fetch (BASE_URL + page, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      },
    });
  }

  const values = await getScientists();
  return values;
}

async function deleteScientist(id) {
  const delScientist = () => {
    return fetch (BASE_URL + id, {
      method: "DELETE",
      mode: 'cors',
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      },
    }).then((response) => response);
  }

  const result = await delScientist();
  return result;
}

async function createScientist(body) {
  const crtScientist = () => {
    return fetch (BASE_URL, {
      method: "POST",
      mode: 'cors',
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      },
      body: body,
    }).then((response) => response);
  }

  const result = await crtScientist();
  return result;
}

async function editScientist(body, id) {
  const updateScientist = () => {
    return fetch (BASE_URL + `${id}/`, {
      method: "PATCH",
      mode: 'cors',
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      },
      body: body,
    }).then((response) => response);
  }

  const result = await updateScientist();
  return result;
}

async function getTeachers() {
  const get = () => {
    return fetch(`${API_DOMAIN}materials/full/staff/`, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Accept": "*/*",
        "Connection": 'keep-alive',
      }
    })
  }
  return await get();
}


export {fetchScientist, deleteScientist, createScientist, editScientist, getTeachers};