import AdminToken from "./AdminToken";
import API_DOMAIN from './CONFIG.js';

const BASE_URL = `${API_DOMAIN}admins/`;

async function getTrainings(id = '') {
  const fetchTrainings = () => {
    return fetch(BASE_URL + 'trainings/' + id, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Content-Type": "application/json",
        "Connection": 'keep-alive',
      },
    });
  }

  const result = await fetchTrainings();
  return result;
}

async function createTraining(body) {
  const crtTraining = () => {
    return fetch (BASE_URL + 'trainings/', {
      method: "POST",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
        "Content-Type": 'application/json',
      },
      body: body,
    });
  }

  const result = await crtTraining();
  return result;
}

async function changeTrainingVisibility(id,visibility) {
  const changeVisibility = () => {
    return fetch(BASE_URL + `trainings/${id}/`, {
      method: "PATCH",
      mode: "cors",
      cache: 'default',
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
        "Content-Type": 'application/json',
      },
      body: JSON.stringify({
        visibility: visibility,
      }),
    });
  }

  const result = await changeVisibility();
  return result;
}

async function deleteTraining(id) {
  const delTraining = () => {
    return fetch(BASE_URL + `trainings/${id}/`, {
      method: "DELETE",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      },
    });
  }

  const result = await delTraining();
  return result;
}

async function deleteRecord(id) {
  const delRecord = () => {
    return fetch(BASE_URL + `records/${id}/`, {
      method: "DELETE",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
      },
    })
  }

  const result = await delRecord();
  return result;
}

async function editTraining(id, body) {
  const edit = () => {
    return fetch(BASE_URL + `trainings/${id}/`, {
      method: "PATCH",
      mode: "cors",
      cache: 'default',
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
        "Content-Type": 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  const result = await edit();
  return result;
}

async function editRecord(id,body) {
  const edit = () => {
    return fetch(BASE_URL + `records/${id}/`,{
      method: "PATCH",
      mode: "cors",
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
        "Content-Type": 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  const result = await edit();
  return result;
}

async function createRecord(body) {
  const create = () => {
    return fetch(BASE_URL + `records/`, {
      method: "POST",
      mode: 'cors',
      cache: "default",
      headers: {
        "Authorization":  AdminToken.value,
        "Accept": "*/*",
        "Connection": 'keep-alive',
        "Content-Type": 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  const result = await create();
  return result;
}

export { 
  getTrainings, 
  createTraining, 
  changeTrainingVisibility, 
  deleteTraining, 
  deleteRecord, 
  editTraining,
  editRecord,
  createRecord,
 };