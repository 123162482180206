import React from "react";
import './DocumentList.scss';
import Pagination from "../Pagination/Pagination";
import DocumentCreationForm from "./DocumentCreationForm/DocumentCreationForm";
import DocumentCard from "./DocumentCard/DocumentCard";
import { fetchDocuments } from "../../API/Documents";

const PageSize = 10;

async function getDocuments(page) {
  const result = await fetchDocuments(page);
  return result;
}

class DocumentList extends React.Component {
  state = {
    searchQuery: '',
    formIsVisible: false,
    documentsArr: [],
    currentPage: 1,
    count: 0,
  }

  componentDidMount() {
    this.downloadDocuments(); 
  }

  downloadDocuments(page = 1, search = '') {
    let result = getDocuments(`?page=${page}&title=${search}`);
    result.then((data) => {
      if (!data.ok) {
        throw new Error('Не удалось загрузить список');
      }
      return data.json();
    }).then((dataJSON) => {
      this.useResult(dataJSON);
    }).catch(alert);
  }

  useResult(val) {
    if (Array.isArray(val)) {
      this.setState(() => ({
        documentsArr: [],
        count: 0,
      }));
    } else {
      this.setState(() => ({
        documentsArr: val.results,
        count: val.count,
      }));
    }
  }
  
  setCurrentPage(value) {
    this.setState(() => ({currentPage: value}));
    this.downloadDocuments(value);
  }

  setSearchQuery(event) {
    this.setState(() => ({searchQuery: event.target.value}));
    this.downloadDocuments(1,event.target.value);
  }

  showForm() {
    this.setState(() => {return({formIsVisible: true})})
  }

  hideForm() {
    this.setState(() => {return({formIsVisible: false})});
    this.downloadDocuments(this.state.currentPage);
  }

  render() {
    const { currentPage, count, documentsArr } = this.state;
    const filteredArray = documentsArr;
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    const currentData = filteredArray;
    const firstNumber = firstPageIndex + 1;
    let lastNumber = lastPageIndex;
    if (lastPageIndex <= count) {
      lastNumber = lastPageIndex;
    } else {
      lastNumber = count;
    }
  
    return (
      <div className="document-list__page">
        <div className='page__header'>
          <div className='info'>
            <h2 className='info__title'>Консенсус документы</h2>
          </div>
          <div className='navigation'>
            <input
              type="search"
              placeholder='Поиск по названию'
              className='navigation__search-bar'
              value={this.state.searchQuery}
              onChange={(event) => this.setSearchQuery(event)}
            />
            <button 
              className='navigation__add-button'
              onClick={() => {
                this.setState(() => ({isEditing: false}));this.showForm()
            }}
            >
              Добавить
            </button>
            {this.state.formIsVisible &&
              <DocumentCreationForm
                show={this.state.formIsVisible}
                handleClose={this.hideForm.bind(this)}
                isEditing={false}
                object={{}}
              />
            }
          </div>
        </div>
        <div className="page__pagination">
          <div className="page__table">
            <div className="grid__table table__head">
              <div className="head__number head">№</div>
              <div className="head__photo head">Фото</div>
              <div className="head__name head">Название публикации</div>
              <div className="head__date head">Дата</div>
              <div className="head__desc head">Описание</div>
              <div className="head__edit head">Ред.</div>
            </div>
            {currentData.map(
              (item)=> {return(
                <DocumentCard
                  object={item}
                  key={item.id}
                  update={this.downloadDocuments.bind(this)}
                  page={this.state.currentPage}
                />  
              )}
            )}
          </div>
          <div className='pagination__nav'>
            <div className='pagination__nav--number'>
              {`${firstNumber}-${lastNumber} из ${count}`}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={count}
              pageSize={PageSize}
              onPageChange={page => this.setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentList;